import {
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Box,
  Icon,
  useColorModeValue,
  Tooltip,
  Avatar,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  Grid,
  Spinner,
  Wrap,
  WrapItem,
  Center,
  Stack, 
  HStack,
  VStack,
} from "@chakra-ui/react";

import { formatEther } from "@ethersproject/units";
import Identicon from "./Identicon";

import { FaWallet, FaCheck, } from "react-icons/fa";
import { MdClose, MdCheck } from "react-icons/md"

import React, { useEffect } from "react"

import { useAtom } from 'jotai'
import { socketAtom } from "atoms/socketAtom";

import socketIOClient from "socket.io-client";

import { encrypt, decrypt } from "../../utils/aes-wrapper"

import { ethers } from "ethers"
import { accountAtom } from "atoms/accountAtom";

import walletModal from "./WalletModal"

import { useState } from "react"
import { web3Atom } from "atoms/web3Atom";
import { questionAnswersAtom } from "atoms/questionAnswersAtom";

import { uuid } from 'uuidv4';
import { userNameAtom } from "atoms/userNameAtom";
import { userAvatarAtom } from "atoms/userAvatarAtom";


import { sendMessage } from "../../utils/publisher"
import { messageRecieved } from "utils/subscriber";
import { subscribeForMessage } from "utils/subscriber";
import { endSubscription } from "utils/subscriber";
import { getAvatar } from "utils/avatars";
import { getAllAvatars } from "utils/avatars";
import { pokerTableStateAtom } from "atoms/pokerTableStateAtom";
import { userFreeChipsAtom } from "atoms/userFreeChipsAtom";
import { userChipsAtom } from "atoms/userChips";



import {FiRefreshCcw} from "react-icons/fi"


import "./WalletConnectButton-Mobile.css"
import { chatBoxOpenAtom } from "atoms/chatBoxOpenAtom";
import { GetFreeChipsModalOpenAtom } from "atoms/GetFreeChipsModalOpenAtom";
import { getFreeChipsLoadingAtom } from "atoms/getFreeChipsLoadingAtom";
import { GetRealChipsModalOpenAtom } from "atoms/GetRealChipsModalOpenAtom";
import { updateBalanceTriggerAtom } from "atoms/updateBalanceTriggerAtom";
import { balanceLoadingAtom } from "atoms/balanceLoadingAtom";

import { toast } from 'react-toastify';
import ENDPOINT from "./Endpoint";



export default function WalletConnectButtonMobile({ handleOpenModal }) {

  const [socket, setSocket] = useAtom(socketAtom)
  const [account, setAccount] = useAtom(accountAtom)

  const [userName, setUserName] = useAtom(userNameAtom)
  const [userAvatar, setUserAvatar] = useAtom(userAvatarAtom)

  const [userFreeChips, setUserFreeChips] = useAtom(userFreeChipsAtom)
  const [userChips, setUserChips] = useAtom(userChipsAtom)


  const [tableState, setTableState] = useAtom(pokerTableStateAtom)

  const [chatBoxOpen, setChatBoxOpen] = useAtom(chatBoxOpenAtom)

  const [openGetFreeChipsModal, setOpenGetFreeChipsModal] = useAtom(GetFreeChipsModalOpenAtom)
  const [openGetRealChipsModal, setOpenGetRealChipsModal] = useAtom(GetRealChipsModalOpenAtom)
  

  const [freeChipsLoading, setFreeChipsLoading] = useAtom(getFreeChipsLoadingAtom)

  const [updateBalanceTrigger] = useAtom(updateBalanceTriggerAtom)


  const [name, setName] = useState("")

  const [nameEditMode, setNameEditMode] = useState(false)
  const [nameSaving, setNameSaving] = useState(false)

  const [questionAnswers, setQuestionAnswers] = useAtom(questionAnswersAtom)


  const { web3Loading, getweb3 } = walletModal();
  const [myWeb3, setMyWeb3] = useAtom(web3Atom)

  async function handleConnectWallet() {

    try {

      if (!isConnected()) {

        const web3 = await getweb3();
        if (web3) {
          setMyWeb3(web3)
          const accounts = await web3.eth.getAccounts();
          const _account = accounts[0]
          setAccount(_account)
          // console.log(_account)

        }

      }


    } catch (err) {
      if (err.message === "INVALID_NETWORK") {
        alert("Please switch to the BSC network")
      }
    }
    // requestForSignIn();
  }


  useEffect( () => {
    requestGetBalance()
}, [updateBalanceTrigger])


  useEffect(() => {

    if (myWeb3 && account) {
      if (!isConnected()) {
        setTimeout(() => {
          requestForSignIn();
        }, 1000);
      }
    }

    return () => {

    }
  }, [account, myWeb3])

  useEffect(() => {

    const sub1 = subscribeForMessage("updateUserName", processUpdateUserName, "wcb1")
    // const sub2 = subscribeForMessage("updateUserNameError", processUpdateUserNameError, "wcb1")

    const sub3 = subscribeForMessage("responseGetBalance", processGetBalance, "wcb1")
    // const sub4 = subscribeForMessage("responseGetFreeChips", processGetFreeChips, "wcb1")


    return () => {
      endSubscription(sub1)
      // endSubscription(sub2)
      endSubscription(sub3)
      // endSubscription(sub4)

      
    }
  }, [])


  let balanceBG = useColorModeValue("black", "black")
  let balanceColor = useColorModeValue("pink.400", "pink.400")

  let BNBColor = useColorModeValue("#aaa", "#aaa")

  let addressBG = useColorModeValue("gray.800", "gray.800")
  let addressColor = useColorModeValue("green.200", "green.200")

  let addressBGHover = useColorModeValue("green.500", "green.500")
  let addressColorHover = useColorModeValue("green.50", "green.50")

  // useEffect(() => {

  //   requestForSignIn(account)

  //   return async () => {
  //     if (socket)
  //     {
  //       await closeSocket(socket)
  //     }      
  //   }
  // }, [account])


  const requestForSignIn = async () => {

    if (!account) {
      return
    }

    // console.log("requestForSignIn : ", account)

    if (socket && socket.socket) {
      await socket.socket.disconnect();
    }

    if (account) {

      setTableState({})


      let error = null

      let clientId = ""
      let signature = ""

      const newSocket = socketIOClient(ENDPOINT,
        {
          autoConnect: false,
          transports: ['websocket'],
          closeOnBeforeunload: false
        }
      )


      newSocket.on('connect', () => {
        // console.log('socket connected');
        setSocket({ socket: newSocket, sharedKey: null })

        if (questionAnswers.findIndex(e => e.account?.toString().toUpperCase() === account?.toString().toUpperCase()) >= 0) {
          const record = questionAnswers[questionAnswers.findIndex(e => e.account?.toString().toUpperCase() === account?.toString().toUpperCase())]
          clientId = record.clientId
        } else {
          clientId = uuid()
        }

        newSocket.emit('authentication', {
          user: account,
          clientId: clientId
        });

      });

      newSocket.on('unauthorized', (reason) => {
        console.error('Unauthorized:', reason);
        error = reason.message;
        setAccount(null)
        setQuestionAnswers([])
        newSocket.disconnect();
      });

      newSocket.on('disconnect', (reason) => {
        console.error(`Disconnected: ${error || reason}`);
        setSocket(null)
      })


      newSocket.on('handshake', async (data) => {

        try {
          var question = data.question

          // if (questionAnswers.findIndex(e => e.account?.toString().toUpperCase() === account?.toString().toUpperCase()) < 0) {
          //   const provider = new ethers.providers.Web3Provider(myWeb3.eth.currentProvider)

          //   const text = (
          //     <div>
          //       <h2>Sign Request</h2>
          //       <p>Please sign the message we sent you through your wallet to verify the ownership of your account. This is for your own security and just for the first time you connect your wallet form a new device, and it is absoloutly cost free. If you cannot see the sign request, please make sure to take a look at your pending requests of your wallet.</p>
          //     </div>
          //   )
          //   const options = {
          //     position: "top-center",
          //     autoClose: 60000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     }

          //     toast.info(text, options)
            
          //   ///
          //     const msgParams = JSON.stringify( {
          //       domain: {
          //         chainId: 56,
          //         name: 'Green Joker',
          //         verifyingContract: '0x8F4778C2719Ec3fAdad06d03B4e37B1A3762c445',
          //         version: '1',
          //       },

          //       message: {MessageToSign : question},
          //       primaryType: 'Question',
          //       types: {
          //         EIP712Domain: [
          //           { name: 'name', type: 'string' },
          //           { name: 'version', type: 'string' },
          //           { name: 'chainId', type: 'uint256' },
          //           { name: 'verifyingContract', type: 'address' },
          //         ],

          //         Question: [
          //           { name: 'MessageToSign', type: 'string' },
          //         ],

          //       }
          //     })

          //     var from = account;
          //     var params = [from, msgParams];
          //     var method = 'eth_signTypedData_v4';

          //     provider._sendAsync(
          //       {
          //         method,
          //         params,
          //         from,
          //       },
          //       function (err, result) {
          //         if (err) return console.error(err);
          //         if (result.error) {
          //           alert(result.error.message);
          //         }
          //         if (result.error) return console.error('ERROR', result);


          //         signature = result.result

          //         console.log({signature})

                  
          //         newSocket.emit('handshake', { answer: question, signature: signature, clientId: clientId, ua: window.navigator.userAgent });

          //       })
            


          // } else {
          //   const record = questionAnswers[questionAnswers.findIndex(e => e.account?.toString().toUpperCase() === account?.toString().toUpperCase())]
          //   signature = record.signature
          //   clientId = record.clientId

          //   newSocket.emit('handshake', { answer: question, signature: signature, clientId: clientId, ua: window.navigator.userAgent });

          // }

          // console.log("signature: ", signature)

          signature = `${question.substr(0,10)}${question.substr(5,8)}${question.substr(7,12)}${question.substr(3,7)}${question.substr(41,13)}`
          newSocket.emit('handshake', { answer: question, signature: signature, clientId: clientId, ua: window.navigator.userAgent });


        } catch (err) {
          console.error(err)
          closeSocket(newSocket)
        }
      });


      newSocket.on('authorized', (data) => {
        // console.log('user athorized.');

        const { key, iv, name, avatar, chips, freeChips } = data
        toast.dismiss();

        // const text = (
        //   <div>
        //     <h2>Verificarion Successful</h2>
        //     <p>Your wallet is successfully verified and connected.</p>
        //   </div>
        // )

        // const options = {
        //   position: "top-center",
        //   autoClose: 2000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   }

        // toast.success(text, options)



        // setQuestionAnswers([...questionAnswers, { account: account, signature: signature, clientId: clientId }])


        const sharedKey = {
          key: Buffer.from(key, 'base64'),
          iv: Buffer.from(iv, 'base64')
        }

        setUserName(name)
        setUserAvatar(avatar)

        // console.log('sharedKey : ');
        // console.log(sharedKey);

        setSocket({ socket: newSocket, sharedKey: sharedKey })
        newSocket.sharedKey = sharedKey

        setBalanceLoading(true)
        sendMessage({type: "requestGetBalance"}, { socket: newSocket, sharedKey: sharedKey })

      });


      newSocket.on('message', (data) => {
        let msg_dec = null;
        try {
          msg_dec = decrypt(newSocket.sharedKey.key, newSocket.sharedKey.iv, data);
          msgReceived(JSON.parse(msg_dec))
        } catch (err) {
          console.error(err);
        }
        //if (receivedCount % 1000 == 0)
      });

      newSocket.open()

    }

  }

  const msgReceived = (msg) => {
    // console.log("message received: ", msg)
    messageRecieved(msg)

  }

  const processUpdateUserName = (msg) => {
    setUserName(msg.name)
    setNameSaving(false)
    setNameEditMode(false)
    toast.dismiss();
  }

  const processUpdateUserNameError = (msg) => {
    setNameSaving(false)

    const text = (
      <div>
        <h2>Inavlid Nickname</h2>
        <p>Nickname is taken, please choose another one.</p>
      </div>
    )

    const options = {
      position: "top-center",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }

    toast.error(text, options)
  }

  const processGetBalance = (msg) => {
    try{
      const {freeChips, realChips} = msg

      setUserFreeChips(freeChips)
      setUserChips(realChips)

      setBalanceLoading(false)

    }catch(err)
    {
      console.error(err)
    }
  }

  const closeSocket = async (_socket) => {
    try {
      if (_socket) {
        _socket.disconnect();
        setSocket(null)
        setAccount(null)
      }
    } catch (err) {
    }
  }

  const isConnected = () => {
    return (socket && socket.socket && socket.sharedKey && socket.socket.connected)
  }

  const isConnecting = () => {
    return (!socket || !socket.socket || (!socket.socket.connected || !socket.sharedKey))
  }

  const saveNewNickName = () => {

    if (name.trim().length < 3) {

      const text = (
        <div>
          <h2>Inavlid Nickname</h2>
          <p>Your nickname shoude be between 3 and 9 characters long.</p>
        </div>
      )
      const options = {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }
  
      toast.error(text, options)



      return
    }

    setNameSaving(true)

    sendMessage({ type: "updateUserName", name: name.trim() }, socket)

  }


  const saveAvatar = (avatar) => {
    setUserAvatar(avatar)
    sendMessage({ type: "updateAvatar", avatar: avatar }, socket)
  }

  const [balanceLoading, setBalanceLoading] = useAtom(balanceLoadingAtom)


  const requestGetBalance = () =>
  {
    if (!balanceLoading)
    {
      setBalanceLoading(true)
      sendMessage({type: "requestGetBalance"}, socket)

      setTimeout(() => {
        setBalanceLoading(false)
      }, 5000);
    }
  }




  const openGetFreeChipsModalClicked = () => {
    setOpenGetFreeChipsModal(true)
  }

  const openGetRealChipsModalClicked = () => {
    setOpenGetRealChipsModal(true)
  }

  const processGetFreeChips = (msg) =>
  {
    const {status, error, freeChips, realChips} = msg
    setFreeChipsLoading(false)
    setOpenGetFreeChipsModal(false)

    if (status === "OK")
    {

      setUserFreeChips(freeChips)
      setUserChips(realChips)
      const text = (
        <div>
          <h2>CONGRATULATIONS</h2>
          <p>You have successfully claimed your free chips.</p>
        </div>
      )
      const options = {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }
  
      toast.success(text, options)
      


      
    }else if (status === "FAILED"){

      const text = (
        <div>
          <h2>ERROR</h2>
          <p>{error}</p>
        </div>
      )
      const options = {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        }
  
      toast.error(text, options)
    }
  }

  const AvatarComponent = () => {
    return (
      <React.Fragment>
          <div style={isConnected() ? {display:"inherit"} : {display:"none"}}>
            <Popover>
              <PopoverTrigger>
                <Box px="3" style={{ cursor: "pointer" }} >
                  <Tooltip label="click to choose your avatar">
                    {userAvatar ? (
                      <img src={getAvatar(userAvatar)} style={{borderRadius:"50%"}} width="50px" />

                    ) : (
                      <Avatar size="sm" name={userName} />
                    )}
                  </Tooltip>
                </Box>
              </PopoverTrigger>
              <PopoverContent bg='gray.700' color='white' >
                <PopoverHeader fontWeight='normal'>Choose your avatar</PopoverHeader>
                {/* <PopoverArrow bg='gray.500' /> */}
                <PopoverCloseButton bg='gary.100' />
                <PopoverBody>
                <div style={{maxHeight:"50vh", overflowY:"scroll"}}>
                  <Grid templateColumns='repeat(4, 1fr)' gap={6}>
                    {getAllAvatars().map(av => (
                      <Avatar bg="black" src={av.src} style={{ cursor: "pointer" }} onClick={() => { saveAvatar(av.name) }} />
                    ))}
                  </Grid>
                </div>
                </PopoverBody>

              </PopoverContent>
            </Popover>
            </div>

      </React.Fragment>
    )
  }


  const ConnetingComponent = () => {
    return (
      <React.Fragment>
        {isConnecting() && (
          <React.Fragment>
            <Box className="only-desktop" px="3">
              <Text color={"orange.200"} fontSize="lg" fontWeight="400">
                <span style={{ fontWeight: "400", fontSize: "0.9rem" }}> Connecting...  </span>
              </Text>
            </Box>

            <Box className="only-mobile" px="3">
              <Spinner color='orange.200' />
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  const NickNameComponent = () => {
    return (
      <React.Fragment>

        {nameSaving && (
          <Box px="3">
            <Text color={"orange.200"} fontSize="sm" fontWeight="700">
              {/* {userBalance ? parseFloat(formatEther(userBalance)).toFixed(3) : ""} */}
              <span style={{ fontWeight: "400", fontSize: "0.8rem" }}> Saving...  </span>
            </Text>
          </Box>
        )}

        <div style={isConnected() && userName && !nameEditMode ? { display: "block" } : { display: "none" }}>
          <Tooltip label="click to change your nickname">
            <Box className="nickname" onClick={() => { setName(userName); setNameEditMode(true) }}>
              <Text fontSize="sm" fontWeight="700">
                {/* {userBalance ? parseFloat(formatEther(userBalance)).toFixed(3) : ""} */}
                <span style={{ fontWeight: "400", fontSize: "0.8rem" }}> {userName} </span>
              </Text>
            </Box>
          </Tooltip>
        </div>

          <div style={ isConnected() && userName && nameEditMode ? {display: "block"} : {display:"none"}}>
          <React.Fragment>
            {!nameSaving && (
              <React.Fragment>
                <Tooltip label="cancel">
                  <IconButton
                    // variant='outline'
                    onClick={() => { setNameEditMode(false) }}
                    colorScheme='gray'
                    mr="5px"
                    size="sm"
                    fontSize='0.9rem'
                    icon={<MdClose />}
                  />
                </Tooltip>

                <Tooltip label="save changes">
                  <IconButton
                    // variant='outline'
                    onClick={saveNewNickName}
                    colorScheme='green'
                    mr="5px"
                    size="sm"
                    fontSize='0.9rem'
                    icon={<MdCheck />}
                  />
                </Tooltip>


              </React.Fragment>
            )}

            <Input autoFocus disabled={nameSaving} size="sm" fontSize="0.8rem" fontWeight="400" maxLength="9" bg="white" color="black" width="100px" variant='outline' value={name} onKeyDown={event => { if (event.key === 'Enter') { saveNewNickName() }; if (event.key === 'Escape') { setNameEditMode(false) } }} onChange={(event) => setName(event.target.value)} />
          </React.Fragment>
         </div>

      </React.Fragment>
    )
  }

  const WalletComponent = () => {
    return (
      <React.Fragment>
        <Box
          display="flex"
          alignItems="center"
          background={balanceBG}
          borderRadius="xl"
          py="0"
        >


          {isConnected() &&
            <Tooltip label={balanceLoading ? "" : "Refresh My Balance"}>
              <p style={{ cursor: balanceLoading ? "" : "pointer" }} onClick={requestGetBalance}>
                <Icon as={FiRefreshCcw} style={{ fontSize: "0.9em", margin: "5px 15px 0px 15px" }} className={balanceLoading ? "refresh-balance-button-loading" : "refresh-balance-button"} />
              </p>
            </Tooltip>
          }


          {isConnected() &&
            <Tooltip label="Click to buy BUSD chips">
              <p style={{ color: "#aaa", padding: "0px 15px", textAlign: "center", marginRight: "5px", cursor: "pointer", borderLeft: "1px solid #333" }} onClick={openGetRealChipsModalClicked}>
                <p className="title-real-chips" style={{fontSize:"0.7em"}}> BUSD Chips</p>
                <span style={{ fontWeight: "500", color: "#edd28c", fontSize: "0.85em" }}>{`${userChips?.toLocaleString("us", { minimumFractionDigits: 0 }) || ""}`} </span> $
              </p>
            </Tooltip>
          }

          {isConnected() &&
            <Tooltip label="Click to claim your free chips">
              <p style={{ color: "#aaa", padding: "0px 15px", textAlign: "center", borderLeft: "1px solid #333", cursor: "pointer" }} onClick={openGetFreeChipsModalClicked} >
                <p className="title-free-chips" style={{fontSize:"0.7em"}}> Free Chips</p>
                <span style={{ fontWeight: "500", color: "#82ecff", fontSize: "0.85em" }}>{`${userFreeChips?.toLocaleString("us", { minimumFractionDigits: 0 }) || ""}`} </span> $
              </p>
            </Tooltip>
          }

          <Button
            onClick={handleOpenModal}
            bg={addressBG}
            border="1px solid transparent"
            color={isConnected() ? addressColor : "orange.200"}
            _hover={{
              border: "1px",
              borderStyle: "solid",
              borderColor: { addressBGHover },
              backgroundColor: { addressBGHover },
              color: { addressColorHover }
            }}
            borderRadius="xl"
            m="1px"
            px={3}
            height="38px"
          >
            <Text padding="5px" fontSize="xs" fontWeight="normal" mr="2">
              {account &&
                `${account.slice(0, 6)}...${account.slice(
                  account.length - 4,
                  account.length
                )}`}
            </Text>
            <Icon as={FaWallet} />
          </Button>
        </Box>


      </React.Fragment>
    )
  }




  return (
    <React.Fragment>

      <Box
        alignItems="center"
        // background={balanceBG}
        borderRadius="xl"
        py="0"
        style ={ account ? {display: "flex"} : {display: "none"}}
      >

        <div>
          <Wrap align="center">            
            <WrapItem>
              <AvatarComponent />
            </WrapItem>
            <WrapItem>
              <NickNameComponent />
            </WrapItem>
            <WrapItem>
              <ConnetingComponent />
            </WrapItem>
            <WrapItem>
              <WalletComponent />
            </WrapItem>
          </Wrap>
        </div>
      </Box>

      <Button
        isLoading={web3Loading()}
        loadingText="Connect Wallet"
        className={"wallet-connect-button"}
        colorScheme={"pink"}
        size="xl"
        variant="outline"
        p="16px"
        onClick={handleConnectWallet}
        style ={ !account ? {display: "flex"} : {display: "none"}}
        >
        Connect Wallet
      </Button>

    </React.Fragment>
  )
}