import { atom} from 'jotai'
import { accountAtom } from './accountAtom'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const MySeatAtom = atom( (get) => {
    const account = get(accountAtom)

    if (!account)
    {
        return null
    }

    const state = get(pokerTableStateAtom)
    if (state && state.seats)
    {
        const seat = state.seats.find(s => 
            s.account && (s.account.toString().toUpperCase()) === (account.toString().toUpperCase())        
        )

        return seat
    }

    return null

})