import { atom} from 'jotai'
import { accountAtom } from './accountAtom';
import { holeCardsAtom } from './holeCardsAtom';
import { PlayerPositionsAtom } from './PlayerPositionsAtom'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const PlayersAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    const account = get(accountAtom)
    const holeCards = get(holeCardsAtom)
    if (state && state.seats && state.seats.length > 0 && account) {
        let seats = state.seats
        const positions = get(PlayerPositionsAtom)



        seats.forEach((s,index) => {
            s.pos = positions[index].pos
            s.isDealer = (s.no === state.dealerPosition)
            s.timeOut = state.turnTimeout
            s.turn = (state.turn === s.no)
            s.showBetOnTable = ((s.lastRoundPutOnTable > 0) && state.round < 5 )
            s.lastBetValue = s.lastRoundPutOnTable
            s.cards = []
            if (account.toString().toUpperCase() === s.account.toString().toUpperCase() && holeCards)
            {
                s.cards = holeCards
            }

            if ((state.state === 3) && state.winners && (state.winners.length > 0))
            {
                const w = state.winners.find(w => w.seatNo === s.no) 
                if (w)
                {
                    s.winner = true
                    s.winnerDesc = w.descr
                }
            }
            if ((state.state === 3 || state.state === 2) && (state.showDownPlayers) && (state.showDownPlayers.length > 0))
            {
                const sd = state.showDownPlayers.find(sd => sd.seat.no === s.no)
                if (sd)
                {
                    s.cards = [...(sd.cards)]
                }else{
                    s.cards = []
                } 
            }
        });
    
        return seats
    }

    return []


})