// Chakra imports
import { ChakraProvider, Portal, useDisclosure, useColorMode, Box } from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes.js";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";

import SearchBox from "components/Navbars/SearchBox";

import GameDashboard from "../views/Dashboard/GameDashboard"

import { FullScreen, useFullScreenHandle } from "react-full-screen";


import { useAtom } from "jotai";
import { chatBoxOpenAtom } from "atoms/chatBoxOpenAtom";

import "./Admin.css"
import { chatMessagesAtom } from "atoms/chatMessagesAtom";
import { socketAtom } from "atoms/socketAtom";
import { userNameAtom } from "atoms/userNameAtom";
import { fullScreenModeAtom } from "atoms/fullScreenModeAtom";

import img_bg1 from "../assets/img/bg/joker-bg-1.png"
import img_bg_theme1 from "../assets/img/bg/game-table-bg-theme1.png"
import img_bg_theme2 from "../assets/img/bg/game-table-bg-theme2.png"

import img_card_back from "../assets/img/cards/card-back.png"
import img_dealer_button from "../assets/img/chips/dealer-button.png"
import img_joker_chip from "../assets/img/chips/joker-chip.png"
import img_winner_trophy from "../assets/img/others/winner-trophy.svg"
import img_poker_table_1 from "../assets/img/gk/poker-table-1.png"
import img_poker_table_2 from "../assets/img/gk/poker-table-2.png"

import img_free_chips_logo from "../assets/img/others/free-chips-logo.png"
import img_real_chips_logo from "../assets/img/others/real-chips-logo.png"

import img_cloud1 from "../assets/img/clouds/cloud1.png"
import img_cloud2 from "../assets/img/clouds/cloud2.png"
import img_cloud3 from "../assets/img/clouds/cloud3.png"

import img_column from "../assets/img/clouds/column.png"
import img_city from "../assets/img/clouds/city-cutout.png"

import gif_fastgame from "../assets/img/gif/fast-game.gif"
import gif_ring from "../assets/img/gif/ring.gif"
import gif_ring2 from "../assets/img/gif/ring-2.gif"



import ch_1_1 from "../assets/img/chipstack/1-1.png"
import ch_1_2 from "../assets/img/chipstack/1-2.png"
import ch_1_3 from "../assets/img/chipstack/1-3.png"
import ch_1_4 from "../assets/img/chipstack/1-4.png"
import ch_2k_1 from "../assets/img/chipstack/2k-1.png"
import ch_2k_2 from "../assets/img/chipstack/2k-2.png"
import ch_2k_3 from "../assets/img/chipstack/2k-3.png"
import ch_2k_4 from "../assets/img/chipstack/2k-4.png"
import ch_2k_5 from "../assets/img/chipstack/2k-5.png"
import ch_2k_6 from "../assets/img/chipstack/2k-6.png"
import ch_2k_7 from "../assets/img/chipstack/2k-7.png"
import ch_5_1 from "../assets/img/chipstack/5-1.png"
import ch_5_2 from "../assets/img/chipstack/5-2.png"
import ch_5_3 from "../assets/img/chipstack/5-3.png"
import ch_5_4 from "../assets/img/chipstack/5-4.png"
import ch_25_1 from "../assets/img/chipstack/25-1.png"
import ch_25_2 from "../assets/img/chipstack/25-2.png"
import ch_25_3 from "../assets/img/chipstack/25-3.png"
import ch_100_1 from "../assets/img/chipstack/100-1.png"
import ch_100_2 from "../assets/img/chipstack/100-2.png"
import ch_100_3 from "../assets/img/chipstack/100-3.png"
import ch_100_4 from "../assets/img/chipstack/100-4.png"
import ch_500_1 from "../assets/img/chipstack/500-1.png"
import ch_500_2 from "../assets/img/chipstack/500-2.png"
import ch_500_3 from "../assets/img/chipstack/500-3.png"




import spinSvg from "../assets/img/others/spin.svg"
import { getAllAvatars } from "utils/avatars";
import { tableThemeAtom } from "atoms/tableThemeAtom";

const imageLoadingArrayStatic = [
  img_bg1,
  img_bg_theme1,
  img_bg_theme2,
  img_card_back,
  img_dealer_button,
  img_joker_chip,
  img_winner_trophy,
  img_poker_table_1,
  img_poker_table_2,
  img_free_chips_logo,
  img_real_chips_logo,
  // img_cloud1,
  // img_cloud2,
  // img_cloud3,
  // img_city,
  // img_column,

  gif_fastgame,
  gif_ring,
  gif_ring2,

  ch_1_1,
  ch_1_2,
  ch_1_3,
  ch_1_4,
  ch_2k_1,
  ch_2k_2,
  ch_2k_3,
  ch_2k_4,
  ch_2k_5,
  ch_2k_6,
  ch_2k_7,
  ch_5_1,
  ch_5_2,
  ch_5_3,
  ch_5_4,
  ch_25_1,
  ch_25_2,
  ch_25_3,
  ch_100_1,
  ch_100_2,
  ch_100_3,
  ch_100_4,
  ch_500_1,
  ch_500_2,
  ch_500_3,
]


export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  // ref for main panel div
  const mainPanel = React.createRef();
  // functions for changing the states from components
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [chatBoxOpen, setChatBoxOpen] = useAtom(chatBoxOpenAtom)

  const [chatMessages] = useAtom(chatMessagesAtom)
  const [socket] = useAtom(socketAtom)

  const [userName] = useAtom(userNameAtom)

  const [fullScreenMode, setFullScreenMode] = useAtom(fullScreenModeAtom)

  const [imagesLoaded, setImagesLoaded] = React.useState(0)

  const [tableTheme] = useAtom(tableThemeAtom)

  const handleFullScreen = useFullScreenHandle();

  const isConnected = () => {
    return (socket && socket.socket && socket.sharedKey && socket.socket.connected)
  }

  const [tableId, setTableId] = React.useState("")
  const [urlLoaded, setUrlLoaded] = React.useState(false)

  const [imageLoadingArray, setImageLoadingArray] = React.useState(null)


  useEffect(() => {

    const path = window.location.pathname
    const urlElements = window.location.pathname.split("/");
    if (urlElements && urlElements.length >= 3 && urlElements[1] === "tables") {
      setTableId(urlElements[2])
    }

    setUrlLoaded(true)

    buildImageLoadingArray()

  }, [])

  const buildImageLoadingArray = () => {
    const avatarArray = []
    const avatars = getAllAvatars()
    avatars.forEach(av => {
      avatarArray.push(av.src)
    });

    setImageLoadingArray([...imageLoadingArrayStatic, ...avatars])
  }


  const LoadingComponent = () => {
    return (
      <React.Fragment>

        {imageLoadingArray && imageLoadingArray.length > 0 && (imagesLoaded < imageLoadingArray.length) && imageLoadingArray.map(image => (
          <img src={image} style={{ display: "none" }} onLoad={() => setImagesLoaded(prev => prev + 1)} />
        ))}

        <div class="divLoader" style={(imageLoadingArray && imageLoadingArray.length > 0 && imagesLoaded < imageLoadingArray.length) ? { display: "flex" } : { display: "none" }}>
          <img src={spinSvg} alt="loading" />
          <div>
            <p style={{ color: "#8dfe71", fontSize: "1.5em", fontFamily: "'Poppins', sans-serif", textShadow: "4px 4px 4px 4px rgba(211, 255, 200, 0.5)" }}> GREEN JOKER is Loading...</p>
          </div>
        </div>

      </React.Fragment>
    )
  }


  useEffect(() => {

    try {

      if (handleFullScreen) {
        if (fullScreenMode) {
          handleFullScreen.enter()
        } else {
          if (handleFullScreen.active) {
            handleFullScreen.exit()
          }
        }

        return () => {
          if (handleFullScreen.active) {
            handleFullScreen.exit()
          }
        }
      }
    } catch (err) {
      console.error(err)
    }

  }, [fullScreenMode])

  const fullscreenchanged = (_full) => {
    if (!_full) {
      setFullScreenMode(false)
    }
  }


  const containerRef = React.useRef()

  return (

    <ChakraProvider theme={theme} resetCss={false}>

      {/* <LoadingComponent /> */}

      {/* <div className="game-container" style={(urlLoaded && tableId) && (imageLoadingArray && imageLoadingArray.length > 0 && imagesLoaded >= imageLoadingArray.length) ? { display: "block" } : { display: "none" }}>

        <FullScreen handle={handleFullScreen} onChange={fullscreenchanged}>
          <div ref={containerRef}>
            <AdminNavbar
              parentRef={containerRef}
              onOpen={onOpen}
              logoText={""}
              {...rest}
            />
            <GameDashboard tableId={tableId} tableTheme={tableTheme} />

          </div>
        </FullScreen>

      </div> */}


      {/* {urlLoaded && !tableId && ( */}
        <div style={{ width: "100%", textAlign: "center", fontSize: "1.5em", color: "#fafafa", padding: "50px" }}>
          <p> Access Denied!</p>
        </div>
      {/* )} */}
    </ChakraProvider>
  )

}
