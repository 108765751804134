import React from 'react';
import { Avatar } from '@chakra-ui/react'
import './Chat.css';
import { getAvatar } from 'utils/avatars';
import { IoMdArrowDropdown } from "react-icons/io";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
} from '@chakra-ui/react'
import { flat } from '@metamask/jazzicon/colors';


function MessageBox(props) {
  const [msgMouseIn, setMsgMouseIn] = React.useState(false)
  // console.log("props.data.message.imoji   ",props.data.message.imoji)
  // console.log("props.data.message.message.length    ",props.data.message.message.length)

  const addReplyBody = () => {
  let messageBody = {'message': props.data.message.message,'Id': props.data.id.toString(),'userName':props.data.userName}
    props.setReply(messageBody)
  }
  return (
    // <div key={props.data.id.toString()} className={props.messageBoxClass} style={props.messageBoxClass.indexOf("Message-Repeat") < 0  ? {height:`${ Math.floor(((props.data.message.message.length) / 21) + 1) * 20 + 30}px` } :  props.data.message.message ? {height:`${ Math.floor(((props.data.message.message.length) / 21) + 1) * 20}px`}: {}}>
  
    <div key={props.data.id.toString()} className={props.messageBoxClass}>
      <div>
        {props.avatarClass ?
            <React.Fragment>
                <span className={props.avatarClass} >

                    {props.data.avatar ? (
                      <img className={props.avatarClass} src={getAvatar(props.data.avatar)} style={{borderRadius:"50%"}} width="70px" />

                    ) : (
                      <Avatar className={props.avatarClass}  size="sm" name={props.data.userName} />
                    )}


                </span>
                <p style={props.myself === true ?{ textAlign: 'right'}:{}} className="Message-auth">{props.data.userName}</p>
            </React.Fragment> : null
        }
        
          <div class={`Message-Text-Reply ${props.data.message.reply && "Message-Text-Reply-Exist"}`} 
          //  style={(props.length <= 1) ? {height: '100px'}: {}}
           >
            
            
              <div className="Message-Date-Details"
              // onMouseEnter={() => setMsgMouseIn(true)} 
              >
              <Popover offset={[0,0]} isOpen={msgMouseIn} placement='top'>
            <PopoverTrigger>
              <div class={`Message-Text ${props.myself && "Message-Text-Own"}`} style={{}}
                onClick={() => setMsgMouseIn(true)}
                onMouseEnter={() => setMsgMouseIn(true)}
                onMouseLeave={() => setMsgMouseIn(false)}
                style={((props.data.message.imoji === true) && (props.data.message.message.length <= 8)) ? props.myself === true ? {border: '0px', backgroundColor: 'inherit', fontSize: '2em', textAlign: 'right' } : {border: '0px', backgroundColor: 'inherit', fontSize: '2em' } : {}}
                >
                {props.data.message.reply && <div className='Message-Text-Reply-box'>
                  
                  <div style={{ fontSize: '6em', textAlign: 'left', maxWidth: "80%"  }}>
                  <span style={{fontWeight:"600"}}>{props.data.message.reply.userName}{':'}</span> {(props.data.message.reply.message.length > 50) ? `${props.data.message.reply.message.substring(0,50)}...` : props.data.message.reply.message}
                  </div>
                  </div>}
                  {props.data.message.message}
                <div className={`Message-Option-Box ${msgMouseIn && "Message-Option-Box-active"}`}>{msgMouseIn && !props.data.message.imoji &&
                  <div onClick={() => {addReplyBody(), setMsgMouseIn(false)}} style={{ backgroundColor: "rgba(255,255,255,0.8)", fontSize: '0.8em', color: '#111',  display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.4em', cursor: 'pointer', fontWeight: 'bold', padding: '0.2em 0.5em', margin:"0.3em 0" }}>
                    REPLY
                  </div>
                } </div>
              </div>
            </PopoverTrigger>
                  {/* <PopoverContent style={{ width: '100%', fontSize: '0.7em', WebkitBoxShadow: 'none', cursor: 'pointer', backgroundColor: 'rgb(38, 38, 38)', minHeight: '22px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', bottom: '-5px' }}> */}
                    {/* <PopoverArrow /> */}
                    {/* <PopoverBody onClick={() => {addReplyBody(), setMsgMouseIn(false)}}>
                      <div style={{width:"100%", height:"100%"}} onMouseLeave={() => setMsgMouseIn(false)} >
                         Reply
                       </div>  
                    </PopoverBody>
                    </PopoverContent> */}
                  </Popover>

          </div> 
        </div>
        </div>
    </div>
  
  )}
export default MessageBox;
