import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { useState } from "react"
import { useAtom } from 'jotai'
import { accountAtom } from "atoms/accountAtom";
import { web3ModalAtom } from "atoms/web3ModalAtom";
import { socketAtom } from "atoms/socketAtom";

const infuraId="0f3ee7e8e5164c809aaaa384e6eddfeb"

export default function WalletModal() {

    const [account, setAccount] = useAtom(accountAtom)

    const [socket, setSocket] = useAtom(socketAtom)


    const [myWeb3Modal, setMyWeb3Modal] = useAtom(web3ModalAtom)

    const [loading, setLoading] = useState(false);

    return {
         web3Loading : () => {
            return loading
        }
        ,
         getweb3: async () => {
            try{

            setLoading(true);
            let web3Modal;
            let provider;
            let web3;
            let providerOptions;
            providerOptions = {
                metamask: {
                    id: "injected",
                    name: "MetaMask",
                    type: "injected",
                    check: "isMetaMask"
                },
                walletconnect: {
                    package: WalletConnectProvider, // required
                    options: {
                        rpc: {
                          56: 'https://bsc-dataseed.binance.org/',
                        },
                        network: 'binance',
                        name: "WalletConnect",
                        chainId: 56,
                        infuraId: infuraId,
                      },
                }
            };

            web3Modal = new Web3Modal({
                network: "binance",
                cacheProvider: false,
                theme: "dark",   
                providerOptions,
                 
            });

            setMyWeb3Modal(web3Modal)

            web3Modal.clearCachedProvider();
            
            provider = await web3Modal.connect()
            provider.on('error', e => console.error('WS Error', e));
            provider.on('end', e => console.error('WS End', e));

            provider.on("disconnect", (error) => {
                console.log(error);
            });
            provider.on("connect", (info) => {
                console.log(info);
            });

            provider.on("accountsChanged", async (accounts) => {

                // if (!(socket && socket.socket && socket.sharedKey)) {
                //     // await socket.socket.disconnect(); 

                //     if (accounts && accounts.length > 0) {
                //         const _account = accounts[0]
                //         setAccount(_account)
                //         console.log(_account)
                //     } else {
                //         setAccount(null)
                //     }
                // }

              });


            provider.on("chainChanged", async (chainId) => {

                // if (socket && socket.socket && socket.sharedKey) {
                //     // await socket.socket.disconnect(); 
                //     return
                // }
                // if (chainId !== 0x38)
                // {
                //     setAccount(null)
                // }

                // console.log(chainId);
            });

            web3 = new Web3(provider);

            let networkError = false

            const chainId = await web3.eth.getChainId()
            if (chainId === 0x38)
            {
                // const accounts = await web3.eth.getAccounts();
                // const _account = accounts[0]
                // setAccount(_account)
                // console.log(_account)
            }else
            {
                // throw new Error("INVALID_NETWORK")
                networkError = true

                try {
                    console.log("before wallet_switchEthereumChain")
                    setTimeout(() => {
                        if (networkError)
                        {
                            setLoading(false)
                            localStorage.removeItem("walletconnect")
                            alert("Cannot find the BSC network, please make sure you have installed the BSC network on your wallet, and it is set to your default network.")
                        }
                    }, 10000);

                    await web3.currentProvider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: "0x38" }],
                    });

                    console.log("after wallet_switchEthereumChain")


                    networkError = false

                } catch (error) {
                    console.log("catch wallet_switchEthereumChain")

                    if (error.code === 4902) {
                        try {
                            await web3.currentProvider.request({
                                method: "wallet_addEthereumChain",
                                params: [
                                    {
                                        chainId: "0x38",
                                        chainName: "Binance Smart Chain Mainnet",
                                        rpcUrls: ["https://bsc-dataseed.binance.org/"],
                                        nativeCurrency: {
                                            name: "BNB",
                                            symbol: "BNB",
                                            decimals: 18,
                                        },
                                        blockExplorerUrls: ["https://bscscan.com"],
                                    },
                                ],
                            });

                            networkError = false
                        } catch (error) {
                            alert(error.message);
                        }
                    }
                }

            }


            console.log("Before return web3")

            setLoading(false);

            if (networkError)
            {
                throw new Error("INVALID_NETWORK")
            }

         

            return web3;

            }catch(err)
            {
                console.error(err)
                setLoading(false)
                if (err.message === "INVALID_NETWORK")
                {
                    throw err
                }
            }
        },
    }
}
