import { atom} from 'jotai'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const ActionViewAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)

    if (state) 
    {       
        const result = !((state.round > 1) && (state.lastBet === 0))
        return result
    }

    return false

})