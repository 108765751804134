import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";

// import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
// import RTLLayout from "layouts/RTL.js";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
// import MobileLayout from "layouts/Mobile";
import MobileLayout from "layouts/MobileV";


ReactDOM.render(
  // <HashRouter>
  //   <Routes>
  //     {/* <Route path={`/auth`} component={AuthLayout} /> */}
  //     <Route path={`/admin`} element={<AdminLayout/>} />
  //     {/* <Route path={`/rtl`} component={RTLLayout} /> */}
  //   </Routes>

  //   <Navigate from={`/`} to="/admin/dashboard" />


  // </HashRouter>

  <React.Fragment>
    <BrowserView>
      <AdminLayout />
    </BrowserView>
    <MobileView>
      <MobileLayout />
    </MobileView>
  </React.Fragment>

  ,
  document.getElementById("root")
);

