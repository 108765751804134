import { atom} from 'jotai'

import {pokerTableStateAtom} from './pokerTableStateAtom'

export const tableThemeAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    if (state)
    {
        return state.theme
    }

    return 0

})