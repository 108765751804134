import { atom} from 'jotai'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const TableIdAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    if (state)
    {
        return state.tableId
    }
    return ''
})