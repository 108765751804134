import React from 'react';
import './Chat.css';
import { MdSend, MdOutlineEmojiEmotions } from "react-icons/md";
import MessageBox from './MessageBox';
import { sendMessage } from 'utils/publisher';
import { useAtom } from 'jotai';
import { socketAtom } from 'atoms/socketAtom';
import { TableIdAtom } from 'atoms/TableIdAtom';
import {BsChatSquareDots} from "react-icons/bs"
import Icon from '@chakra-ui/icon';
import { chatMessagesAtom } from 'atoms/chatMessagesAtom';
import { userNameAtom } from 'atoms/userNameAtom';
import { AiOutlineClose } from "react-icons/ai";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverArrow,
  } from '@chakra-ui/react'
  import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';

const MAX_MESSAGE_LENGTH = 100

function Chat() {

    const [inputText, setInputText] = React.useState('');
    const [inputImoji, setInputImoji] = React.useState('');
    const [socket] = useAtom(socketAtom)
    const [tableId] = useAtom(TableIdAtom)

    const [chatMessages] = useAtom(chatMessagesAtom)
    const [username] = useAtom(userNameAtom)

    const addNewMessage = (inputText,replyTo) => {
        setReplyTo('')
        if (!inputText || inputText.trim().length === 0)
            return;
        
        let imoji = false
        let msg = inputText
        if  (msg.length > MAX_MESSAGE_LENGTH)
        {
            msg = msg.substr(0,MAX_MESSAGE_LENGTH)
        }
        if (inputText.length === inputImoji.length) {
            imoji = true
        }
        msg = {"message": msg, "reply": replyTo, "imoji": imoji}


        
        setInputText("");
        setInputImoji("");
        sendMessage({ type: "requestChatMessage", message: msg, tableId: tableId }, socket)
    };

    const myRef = React.createRef() 

    const [inputFocused, setInuputFocused] = React.useState(false)
    const [replyTo, setReplyTo] = React.useState(null)

    React.useEffect( () => {
        if (myRef && myRef.current)
        {
            myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }

    }, [chatMessages])

    React.useEffect(() => {
        // console.log("replyTo        ",replyTo)
    }, [replyTo])


    const onEmojiClick = (event, emojiObject) => {
        setInputImoji(inputImoji + emojiObject.emoji)
        setInputText(inputText + emojiObject.emoji)
        // setEmojiOpen(false)

      };

      const [emojiOpen, setEmojiOpen] = React.useState(false)

    return (
        <div className="App">
            <header className="App-header">
                <div className="Chat-Box">
                     <p className="Message-Header">
                         <Icon as={BsChatSquareDots}/>
                     </p> 
                     <div className="Line-Seperator-H"></div> 
                    <div className="Message-Box">

                        <div className="Message-Data">
                            {chatMessages &&  chatMessages.map((msg) =>
                            (msg.userName !== username ?
                                ((chatMessages.find(({ id }) => id === ((msg.id) - 1)))?.userName !== msg.userName ?
                                    <MessageBox setReply={setReplyTo} myself={false} key={msg.id} data={msg} length={chatMessages.length} messageBoxClass={'Message Message-Else'} avatarClass={'avatar avatar-Else'} />
                                    :
                                    <MessageBox setReply={setReplyTo} myself={false} key={msg.id} data={msg} length={chatMessages.length} messageBoxClass={'Message Message-Else Message-Repeat'} />
                                )
                                :
                                ((chatMessages.find(({ id }) => id === ((msg.id) - 1)))?.userName !== msg.userName ?
                                    <MessageBox setReply={setReplyTo} myself={true} key={msg.id} data={msg} length={chatMessages.length} messageBoxClass={'Message Message-Own'} avatarClass={'avatar avatar-Own'} />
                                    :
                                    <MessageBox setReply={setReplyTo} myself={true}  key={msg.id} data={msg} length={chatMessages.length} messageBoxClass={'Message Message-Own Message-Repeat'} />
                                )
                            ))}
                            <div ref={myRef}></div>
                        </div>
                    </div>

                    {/* {replyTo &&  */}
                    <div className={!replyTo ? "Input-Reply-Box":"Input-Reply-Box-active"}>
                    {replyTo &&
                        <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'row' }} >
                            <div className='Input-Repy-Box-Text' >
                                <div className='Input-Repy-Text-Body'>{replyTo.message.length > 10 ? `${replyTo.message.substring(0,10)}...` : replyTo.message}</div>
                                <div className='Input-Repy-Text-UserName'>:{replyTo.userName}</div>
                            </div>
                            <div onClick={() => {setReplyTo('')}} className='Input-Repy-close-icon'>
                                <Icon as={AiOutlineClose} fontSize='1em' color='white' />
                            </div>
                        </div>
                    }
                    </div>
                        {/* } */}
                        <div className={`Type-Box ${inputFocused && "Type-Box-active"}`}>
                            <textarea 
                                // autoFocus
                                maxLength={MAX_MESSAGE_LENGTH} 
                                onFocus={() => setInuputFocused(true)} 
                                onBlur={() => setInuputFocused(false)} 
                                onKeyDown={(event) => { if (event.key === 'Enter') { event.preventDefault(); addNewMessage(inputText,replyTo)} }} 
                                type="text" 
                                className="Type-Input"
                                value={inputText} 
                                placeholder="Type a message..." 
                                onChange={(event) => { setInputText(event.target.value) }} /> 
                            <Popover offset={[0,0]} isOpen={emojiOpen}>
                                <PopoverTrigger>
                                    <div onClick={() => setEmojiOpen(true)} style={{ width:"20px", height:"40px", margin:"5px", cursor:"pointer", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                        <Icon as={MdOutlineEmojiEmotions} style={{fontSize:"1.3em"}} />
                                    </div>
                                </PopoverTrigger>
                                <PopoverContent style={{ width: '100%', fontSize: '0.7em', WebkitBoxShadow: 'none', cursor: 'pointer', backgroundColor: 'rgb(38, 38, 38)', height: '200px', width: '200px', textAlign: 'center', justifyContent: 'center', alignItems: 'center', display: 'flex', bottom: '-5px' }}>
                                <PopoverArrow />
                                    <PopoverBody onMouseLeave={() => setEmojiOpen(false)}>
                                         <Picker onEmojiClick={onEmojiClick} 
                                         pickerStyle={{ 
                                            //  backgroundColor: '#121212',
                                            color: 'black' }} 
                                        //  disableSearchBar={true}
                                         />
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                           <div className="Line-Seperator-V" />
                            <div onClick={() => { addNewMessage(inputText) }} style={{ width:"40px", height:"40px", margin:"5px", cursor:"pointer", display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <Icon as={MdSend} style={{fontSize:"1.2em"}} />
                            </div>
                        </div> 

                </div>
                
            </header>
        </div>
    );
}

export default Chat;
