import { atom} from 'jotai'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const MinPlayersAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    if (state)
    {
        return state.minPlayersToStart
    }

    return 3

})