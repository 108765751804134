import {Hand} from 'pokersolver'

import {useState, useEffect} from "react"


export function GameStateDescription({tableState}){

    const [updateTrigger, setUpdateTrigger] = useState(false)

    useEffect( () => {
        const interval = setInterval(() => {
            setUpdateTrigger(prev => !prev)
        }, 1000);

        return () =>
        {
            if (interval)
            {
                clearInterval(interval)
            }
        }
    }, [])

    return (
        <>
            {(tableState.state === 0) && (
                `Waiting for ${getPlayersCountToStart(tableState)} ${getPlayersCountToStart(tableState) === tableState.minPlayersToStart ? "" : ""} player${getPlayersCountToStart(tableState) > 1 ? "s" : ""}...`
            )}

            {(tableState.state === 1) && (
                 `Starts in ${calcTimeToStart(tableState,updateTrigger)} seconds, players still can join the table`   
            )}
        </>
    )
}

export function getPlayersCountToStart(tableState){

    return  tableState.minPlayersToStart - getReadyPlayers(tableState)
    
}

export function getReadyPlayers(tableState){
    let count = 0
    tableState.seats.forEach(s => {
        if (!s.empty && !s.fold && !s.waiting)
        {
            count++
        }
    })
    return count
}

export function calcTimeToStart(tableState){
    const now  = new Date()
    const startTime = new Date(tableState.startTime)
    const timeLeft =  Math.floor((startTime.getTime() - now.getTime()) / 1000)
    return timeLeft >= 0 ? timeLeft : 0
}

export function getWinnerName(tableState){

    let result = ""

    
    tableState.winners.forEach(w => {
        result = `${result} - ${getPlayer(w.seatNo, tableState).name} - ${w.descr || ""} `
    })

    return result
}

export function getPlayer(seatNo, tableState)
{
    return tableState.seats.find(s => s.no === seatNo)
}

const nextNumber = (_current, _seats) => {
    return (_current < _seats.length) ? _current + 1 : 1; 
}

export const nextAvailableSeat = (current, seats) => {
    let _nextNumber = nextNumber(current, seats)
    do {
        if (!seats[_nextNumber - 1].empty && !seats[_nextNumber - 1].fold && !seats[_nextNumber - 1].waiting)
        {
            return seats[_nextNumber - 1]
        }
        _nextNumber = nextNumber(_nextNumber, seats)
    } while (_nextNumber !== current)

    return seats[current - 1]
}

export const getTimeLeft = (time) =>
{
    const now  = new Date()
    const startTime = new Date(time)
    const timeLeft =  Math.floor((startTime.getTime() - now.getTime()) / 1000)
    return timeLeft >= 0 ? timeLeft : 0
}


export const getRoundName = (no) => {
    switch(no){
        case 1 : 
            return "Pre-flop"
        case 2: 
            return "Flop"
        case 3: 
            return "Turn"
        case 4:
             return "River"   
        case 5:
            return "Showdown"
        default:
            return "-"                     
    }
}


export const getActionNumber = (action) => {
    const _action = action.toLowerCase()
    switch (_action) {
        case "bet":
            return 1
        case "call":
            return 2
        case "fold":
            return 3
        case "check":
            return 4
        case "raise":
            return 5
        default:
            return -1
    }
}

export const getActionName = (actionNumber) => {
    switch (actionNumber) {
        case 1:
            return "bet"
        case 2:
            return "call"
        case 3:
            return "fold"
        case 4:
            return "check"
        case 5:
            return "raise"
        default:
            return ""
    }
}

export const getHandName = (cards) => {
    if (!cards || cards.length === 0)
    {
        return ''
    }
    return (Hand.solve(cards)).name
}

export const getHandSolve = (cards) => {
    if (!cards || cards.length === 0)
    {
        return ''
    }
    return Hand.solve(cards)
}

export const getWinnerHandName = (state) => {
    if (state && state.winners && state.winners.length > 0)
    {
        const winnerNo = state.winners[0].seatNo
        const winnerHand = state.showDownPlayers.find(sd => sd.seat.no === winnerNo).cards
        const winnerHandSolve = Hand.solve([...(state.communityCards), ...winnerHand])
        return winnerHandSolve.name
    }
}


export const getWinnerHandDesc = (state) => {
    if (state && state.winners && state.winners.length > 0)
    {
        const winnerNo = state.winners[0].seatNo
        const winnerHand = state.showDownPlayers.find(sd => sd.seat.no === winnerNo).cards
        const winnerHandSolve = Hand.solve([...(state.communityCards), ...winnerHand])
        return winnerHandSolve.descr
    }
}