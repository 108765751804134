// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Portal,
  Progress,
  SimpleGrid,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Center,
  useColorMode,
  useColorModeValue,
  Spinner,
  Avatar,
  ButtonGroup,
  HStack,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip
} from "@chakra-ui/react";
import { canvasDimensionsAtom } from "atoms/canvasDimensionsAtom";
import { fullScreenModeAtom } from "atoms/fullScreenModeAtom";
import { useAtom } from "jotai";
// assets
// Custom icons
import React, { useState, useEffect } from "react";
import useWindowDimensions from "utils/useWindowDimensions";
// react icons

import "./GameDashboard.css"

import PokerTableArea from "game/PokerTableArea";
import TopArea from "game/TopArea";
import FooterArea from "game/FooterArea";
import { sendMessage } from "utils/publisher";
import { socketAtom } from "atoms/socketAtom";
import { subscribeForMessage } from "utils/subscriber";
import { pokerTableStateAtom } from "atoms/pokerTableStateAtom";
import { stateHistoryAtom } from "atoms/statesHistoryAtom";
import { endSubscription } from "utils/subscriber";
import { accountAtom } from "atoms/accountAtom";
import { holeCardsAtom } from "atoms/holeCardsAtom";
import { MySeatAtom } from "atoms/MySeatAtom";
import { muteSoundAtom } from "atoms/muteSoundAtom";


import chtreeImage from "assets/img/others/chtree.png"

import {BiVolumeMute} from "react-icons/bi";
import {FiVolume2} from "react-icons/fi"
import {ImExit} from "react-icons/im"
import {HiOutlineEye} from "react-icons/hi"

import {MdChair} from "react-icons/md"


// import {AiOutlineFullscreen, AiOutlineFullscreenExit} from "react-icons/ai"

import {BsFullscreen, BsFullscreenExit} from "react-icons/bs"

import "animate.css"
import Chat from "components/Chat/Chat";
import { chatMessagesAtom } from "atoms/chatMessagesAtom";
import { userNameAtom } from "atoms/userNameAtom";
import { LeaveTableAtom } from "atoms/LeaveTableAtom";
import { MyTurnAtom } from "atoms/MyTurnAtom";


import { updateBalanceTriggerAtom } from "atoms/updateBalanceTriggerAtom";
import { viewersCountAtom } from "atoms/viewersCountAtom";
import getBackgroundStyles from "views/TableBackgrounds/BG-Style-Manager";

import ChatGIF from "assets/img/gif/chat.gif"
import adImage from "assets/img/others/goker-ad.png"

import { ToastContainer, toast } from 'react-toastify';
import './ReactToastify.css';
import GameStats from "game/GameStats";


window.onbeforeunload = (event) => {
  const e = event || window.event;
  // Cancel the event
  e.preventDefault();
  if (e) {
    e.returnValue = ''; // Legacy method for cross browser support
  }
  return ''; // Legacy method for cross browser support
};


export default function GameDashboard(props) {


  // console.log("GameDashboard")

  const { height, width } = useWindowDimensions();

  const [canvasDimensions, setCanvasDimenssions] = useAtom(canvasDimensionsAtom)

  const [tableState, setTableState] = useAtom(pokerTableStateAtom)
  const [mySeat] = useAtom(MySeatAtom)

  const [statesHistory, setStatesHistory] = useAtom(stateHistoryAtom)
  const [account] = useAtom(accountAtom)
  const [holeCards, setHoleCards] = useAtom(holeCardsAtom)

  const [muteSound, setMuteSound] = useAtom(muteSoundAtom)

  const [fullScreenMode, setFullScreenMode] = useAtom(fullScreenModeAtom)

  const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom)

  const [leaveTable, setLeaveTable] = useAtom(LeaveTableAtom)

  const [updateBalanceTrigger, setUpdateBalanceTrigger] = useAtom(updateBalanceTriggerAtom)

  const [myTurn] = useAtom(MyTurnAtom)



  
  const [socket] = useAtom(socketAtom)
  


  const getGameAreaStyles = (w, h, fullscreen) => {

    const data = getNewWidthHeight(w, h, fullscreen)
    const style = { width: `${data.newWidth}px`, height: `${data.newHeight}px`, marginTop: `${data.marginTop}px`, marginLeft: `${data.marginLeft}px`, fontSize: `${data.fontSize}em` }
    return style
  }

  const getNewWidthHeight = (w, h, fullscreen) => {
    let widthToHeight = 2
    let topNavBar = fullscreen ? 5 : 70
    let extraPadding = fullscreen ? 0 : 0
    let shiftDown = fullscreen ? 0 : 0//100

    let style = {}
    let newWidth = w, newHeight = h - topNavBar - extraPadding

    const newWidthToHeight = newWidth / newHeight

    if (newWidthToHeight > widthToHeight) {
      newWidth = newHeight * widthToHeight
    } else {
      newHeight = newWidth / widthToHeight
    }

    let fontSize = newWidth / 1400
    const maxFontSize = 1.5
    if (fontSize > maxFontSize)
    {
      fontSize = maxFontSize
    }

    const minFontSize = 0.7
    if (fontSize < minFontSize)
    {
      fontSize = minFontSize
    }
    



    const marginTop = -(newHeight - topNavBar) / 2 + shiftDown
    const marginLeft = -newWidth / 2

    return { newWidth, newHeight, marginTop, marginLeft, fontSize }
  }

  const shouldTiltDevice = (w, h) => {
    return w > 0 && h > 0 && (w / h < 1)
  }

  useEffect(() => {
    const data = getNewWidthHeight(width, height, fullScreenMode)
    setCanvasDimenssions({ width: data.newWidth, height: data.newHeight })
  }, [width, height, fullScreenMode])


  const isConnected = () => {
    return socket && socket.socket && socket.socket.connected && socket.sharedKey && account 
  }

  useEffect(() => {
    if (props.tableId) {

      const sub1 = subscribeForMessage("updateTableState", updateTableState, "gamedash")
      const sub2 = subscribeForMessage("requestViewTableError", requestViewTableError, "gamedash")
      const sub3 = subscribeForMessage("updateHoleCards", updateHoleCards, "gamedash")
      const sub4 = subscribeForMessage("chatMessage", chatMessageRecieved, "gamedash")

      const sub5 = subscribeForMessage("broadcastMessage", broadcastMessageRecieved, "gamedash")

      const sub6 = subscribeForMessage("updateViewersCount", updateViewersCount, "gamedash")


      if (props.tableId && isConnected()) {
        sendMessage({ type: "requestViewTable", tableId: props.tableId }, { socket: socket.socket, sharedKey: socket.sharedKey })
      }

      return () => {
        endSubscription(sub1)
        endSubscription(sub2)
        endSubscription(sub3)
        endSubscription(sub4)
        endSubscription(sub5)
        endSubscription(sub6)
      }
    }

  }, [props.tableId, socket])




  const [viewersCount, setViewersCount] = useAtom(viewersCountAtom)
  const updateViewersCount = (msg) =>
  {
    if (msg.tableId === props.tableId) {
        setViewersCount(msg.count)
    }

  }

  const chatMessageRecieved = (msg) => {
    setChatMessages(prev => [...prev, msg.data])
  }

  const broadcastMessageRecieved = (msg) => {
    const {message, title, severity, timeout} = msg

    const text = (
      <div>
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    )
    const options = {
      position: "top-center",
      autoClose: timeout,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }

      if (severity === "info")
      {
        toast.info(text,options)

      }else if (severity === "success")
      {
        toast.success(text,options)

      }else if (severity === "error")
      {
        toast.error(text,options)
      }else if (severity === "warning")
      {
        toast.warn(text,options)        
      }
  }


  const requestViewTableError = (msg) => {

    const text = (
      <div>
        <h2>ERROR</h2>
        <p>The poker table does not exist or is not available right now.</p>
      </div>
    )
    const options = {
      position: "top-center",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }

      toast.error(text, options)

  }

  const updateTableState = (msg) => {

    if (msg.tableId === props.tableId) {
      setStatesHistory(prev => [...prev, msg.state])
      setTableState({ tableId: msg.tableId,
                     tableName: msg.tableName, 
                     tableType: msg.tableType, 
                     theme: msg.theme, 
                     minBalance: msg.minBalance, 
                     maxBalance: msg.maxBalance,
                     minPlayersToStart: msg.minPlayersToStart,
                     waitTimeToStart: msg.waitTimeToStart,
                     breakTimeBetweenHands: msg.breakTimeBetweenHands,
                     actionTime : msg.actionTime,
                     minBet : msg.minBet,

                       ...msg.state
                      }
                    )
    }


  }

  const updateHoleCards = (msg) =>
  {
    if (msg.tableId === props.tableId) {
      setHoleCards([...msg.holeCards])
    }
  }


    const ChristmassTree = () =>
    {
      return (
        <div className="chtree-image">
          <img src={chtreeImage} />
        </div>
      )
    }

  const leaveMySeat = () => {
    sendMessage({type: "requestImmediateLeaveSeat", tableId: props.tableId }, socket)
    setTimeout(() => {
      setUpdateBalanceTrigger(prev => !prev)
    }, 1000);
  }

  const requestLeaveTable = (leave) => {
    setLeaveTable(leave)
    sendMessage({type: "requestLeaveSeat", leave: leave}, socket)
  }

  const containerRef = React.useRef() 

  return (
    <div ref={containerRef} className={`gameArea ${isConnected() && " gameArea-connected"}`} style={getGameAreaStyles(width, height, fullScreenMode)}>

        <ToastContainer
          theme="dark"
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />


        <div style={isConnected() ? { display: "flex", position:"relative", width:"100%", height:"100%" , overflow:"hidden"} : {display: "none", overflow:"hidden"}}>          
            <div className="gameLeftPanel">
              <Chat/>
            </div>

            {chatMessages && chatMessages.length === 0 && (
              <div className="chat-empty-icon">
                  <img src={ChatGIF}/>
                  <div style={{color:"#d1faf0", textAlign:"center", fontSize:"0.7em"}}>No messages here yet...</div>
              </div>  
            )}

            <div className={`gameCanvas`} style={getBackgroundStyles(props.tableTheme)}>
              <div style={{ position: "relative", width: "100%", height: "100%"}}>
                <TopArea parentRef={containerRef} />
                <PokerTableArea tableTheme={props.tableTheme}/>
                {mySeat && 
                   <FooterArea />
                }
             

                <Tooltip label={muteSound ? 'Unmute Sounds' : `Mute Sounds`}>
                  <div className="mute-button" onClick={() => setMuteSound(!muteSound)}>
                    <Icon style={muteSound ? { display: "block" } : { display: "none" }} as={BiVolumeMute} color="gray" />
                    <Icon style={!muteSound ? { display: "block" } : { display: "none" }} as={FiVolume2} color="aqua" />
                  </div>
                </Tooltip>

                <Tooltip label={fullScreenMode ? 'Exit Full Screen' : `Enter Full Screen`}>
                  <div className="fullscreen-button" onClick={() => setFullScreenMode(!fullScreenMode)}>
                    {fullScreenMode && (
                      <Icon as={BsFullscreenExit} color="#fafafa" />
                    )}

                    {!fullScreenMode && (
                      <Icon as={BsFullscreen} color="#fafafa" />
                    )}
                  </div>
                </Tooltip>

              {mySeat && tableState && tableState.state === 2 && !mySeat.waiting && (
                <Tooltip label={leaveTable ? 'Undo' : `Leave my seat after this round`}>
                  <div className="leave-button" onClick={() => requestLeaveTable(!leaveTable)}>
                    {!leaveTable && (
                      <Icon as={MdChair} color="#f1f1f1" />
                    )}

                    {leaveTable && (
                      <Icon as={MdChair} color="rgb(255, 200, 81)" />
                    )}
                  </div>
                </Tooltip>
              )}

              {mySeat && tableState && (tableState.state === 0 || tableState.state === 1 || mySeat.waiting ) &&  (
                <Tooltip label={`Leave my seat`}>
                  <div className="leave-button" onClick={() => leaveMySeat()}>
                      <Icon as={MdChair} color="#f1f1f1" />
                  </div>
                </Tooltip>
              )}

            <Tooltip label="Online Viewers">
              <div className="viewers-count-icon">
                <Icon as={HiOutlineEye} color="aquamarine" />
                <span className="viewers-count-label">
                  {viewersCount}
                </span>
              </div>
            </Tooltip>

              {mySeat && tableState && tableState.state === 2 && leaveTable && (
                <div className="leave-table-message">
                  You will be out of seat in the the next round, but you can still watch other players.
                </div>
              )}

              </div>
            </div>

          <div className="green-joker-logo">
            {/* <a href="https://www.green-joker.com/" target="_blank">
              <img src={adImage} alt="logo" width={"100%"}/>
            </a> */}
            <GameStats/>
          </div>
          </div> 


        <div style={!isConnected() ? { display: "flex", position: "relative", width: "100%", height: "100%" } : { display: "none" }}>
          <div className="connect-wallet-message">
            Just connect your wallet and enjoy playing <span className="poker-text-highlite">POKER</span> online!
          </div>
        </div>






    </div>


  )

}

