/* eslint-disable */

(function (root, factory) {
    module.exports = factory();
  }(this, () => {
    // Defaults
  
    const defaultInstanceSettings = {
      update: undefined,
      begin: undefined,
      run: undefined,
      complete: undefined,
      loop: 1,
      direction: 'normal',
      autoplay: true,
      offset: 0,
    };
  
    const defaultTweenSettings = {
      duration: 1000,
      delay: 0,
      easing: 'easeOutElastic',
      elasticity: 500,
      round: 0,
    };
  
    // Utils
  
    const is = {
      arr: a => Array.isArray(a),
      fnc: a => typeof a === 'function',
      und: a => typeof a === 'undefined',
    };
  
    // BezierEasing https://github.com/gre/bezier-easing
  
    const bezier = (() => {
      const kSplineTableSize = 11;
      const kSampleStepSize = 1.0 / (kSplineTableSize - 1.0);
  
      function A(aA1, aA2) { return 1.0 - 3.0 * aA2 + 3.0 * aA1; }
      function B(aA1, aA2) { return 3.0 * aA2 - 6.0 * aA1; }
      function C(aA1) { return 3.0 * aA1; }
  
      function calcBezier(aT, aA1, aA2) { return ((A(aA1, aA2) * aT + B(aA1, aA2)) * aT + C(aA1)) * aT; }
  
  
      function bezier(mX1, mY1, mX2, mY2) {
        if (!(mX1 >= 0 && mX1 <= 1 && mX2 >= 0 && mX2 <= 1)) return;
        const sampleValues = new Float32Array(kSplineTableSize);
  
        if (mX1 !== mY1 || mX2 !== mY2) {
          for (let i = 0; i < kSplineTableSize; i += 1) {
            sampleValues[i] = calcBezier(i * kSampleStepSize, mX1, mX2);
          }
        }
  
        return (x) => {
          if (mX1 === mY1 && mX2 === mY2) return x;
          if (x === 0) return 0;
          if (x === 1) return 1;
        };
      }
  
      return bezier;
    })();
  
    const easings = (() => {
      const names = ['Quad', 'Cubic', 'Quart', 'Quint', 'Sine', 'Expo', 'Circ', 'Back', 'Elastic'];
  
      // Elastic easing adapted from jQueryUI http://api.jqueryui.com/easings/
  
      function elastic(t, p) {
        return t === 0 || t === 1 ? t :
          -Math.pow(2, 10 * (t - 1)) * Math.sin((((t - 1) - (p / (Math.PI * 2.0) * Math.asin(1))) * (Math.PI * 2)) / p);
      }
  
      // Approximated Penner equations http://matthewlein.com/ceaser/
  
      const equations = {
        In: [
          [0.550, 0.085, 0.680, 0.530], /* InQuad */
          [0.550, 0.055, 0.675, 0.190], /* InCubic */
          [0.895, 0.030, 0.685, 0.220], /* InQuart */
          [0.755, 0.050, 0.855, 0.060], /* InQuint */
          [0.470, 0.000, 0.745, 0.715], /* InSine */
          [0.950, 0.050, 0.795, 0.035], /* InExpo */
          [0.600, 0.040, 0.980, 0.335], /* InCirc */
          [0.600, -0.280, 0.735, 0.045], /* InBack */
          elastic, /* InElastic */
        ],
        Out: [
          [0.250, 0.460, 0.450, 0.940], /* OutQuad */
          [0.215, 0.610, 0.355, 1.000], /* OutCubic */
          [0.165, 0.840, 0.440, 1.000], /* OutQuart */
          [0.230, 1.000, 0.320, 1.000], /* OutQuint */
          [0.390, 0.575, 0.565, 1.000], /* OutSine */
          [0.190, 1.000, 0.220, 1.000], /* OutExpo */
          [0.075, 0.820, 0.165, 1.000], /* OutCirc */
          [0.175, 0.885, 0.320, 1.275], /* OutBack */
          (t, f) => 1 - elastic(1 - t, f), /* OutElastic */
        ],
        InOut: [
          [0.455, 0.030, 0.515, 0.955], /* InOutQuad */
          [0.645, 0.045, 0.355, 1.000], /* InOutCubic */
          [0.770, 0.000, 0.175, 1.000], /* InOutQuart */
          [0.860, 0.000, 0.070, 1.000], /* InOutQuint */
          [0.445, 0.050, 0.550, 0.950], /* InOutSine */
          [1.000, 0.000, 0.000, 1.000], /* InOutExpo */
          [0.785, 0.135, 0.150, 0.860], /* InOutCirc */
          [0.680, -0.550, 0.265, 1.550], /* InOutBack */
          (t, f) => (t < 0.5 ? elastic(t * 2, f) / 2 : 1 - elastic(t * -2 + 2, f) / 2), /* InOutElastic */
        ],
      };
  
      const functions = {
        linear: bezier(0.250, 0.250, 0.750, 0.750),
      };
  
      Object.keys(equations).forEach((type) => {
        equations[type].forEach((f, i) => {
          functions[`ease${type}${names[i]}`] = is.fnc(f) ? f : bezier.apply(this, f);
        });
      });
  
      return functions;
    })();
  
    // Arrays
  
    function filterArray(arr, callback) {
      const len = arr.length;
      const thisArg = arguments.length >= 2 ? arguments[1] : void 0;
      const result = [];
      for (let i = 0; i < len; i += 1) {
        if (i in arr) {
          const val = arr[i];
          if (callback.call(thisArg, val, i, arr)) {
            result.push(val);
          }
        }
      }
      return result;
    }
  
    function flattenArray(arr) {
      return arr.reduce((a, b) => a.concat(is.arr(b) ? flattenArray(b) : b), []);
    }
  
    function toArray(o) {
      return [o];
    }
  
    // Objects
  
    function cloneObject(o) {
      const clone = {};
      for (const p in o) clone[p] = o[p];
      return clone;
    }
  
    function replaceObjectProps(o1, o2) {
      const o = cloneObject(o1);
      for (const p in o1) o[p] = o2.hasOwnProperty(p) ? o2[p] : o1[p];
      return o;
    }
  
    function mergeObjects(o1, o2) {
      const o = cloneObject(o1);
      for (const p in o2) o[p] = is.und(o1[p]) ? o2[p] : o1[p];
      return o;
    }
  
  
    // Values
  
    function minMaxValue(val, min, max) {
      return Math.min(Math.max(val, min), max);
    }
  
    function getFunctionValue(val, animatable) {
      if (!is.fnc(val)) return val;
      return val(animatable.target, animatable.id, animatable.total);
    }
  
    function getAnimationType(el, prop) {
      if (el[prop] != null) return 'object';
    }
  
  
    function getOriginalTargetValue(target, propName) {
      return target[propName] || 0;
    }
  
    function getRelativeValue(to, from) {
      return to;
    }
  
  
    // Decompose value
  
    function decomposeValue(val) {
      const rgx = /-?\d*\.?\d+/g;
      const value = `${val}`;
      return {
        original: value,
        numbers: value.match(rgx) ? value.match(rgx).map(Number) : [0],
        strings: [],
      };
    }
  
  
    function getAnimatables(targets) {
      const parsed = toArray(targets);
      return parsed.map((t, i) => ({ target: t, id: i, total: parsed.length }));
    }
  
    // Properties
  
    function normalizePropertyTweens(prop, tweenSettings) {
      const settings = cloneObject(tweenSettings);
      return toArray(prop).map((v, i) => {
        // Default delay value should be applied only on the first tween
        const delay = !i ? tweenSettings.delay : 0;
        // Use path object as a tween value
        const obj = { value: v };
  
        // Set default delay value
        if (is.und(obj.delay)) obj.delay = delay;
        return obj;
      }).map(k => mergeObjects(k, settings));
    }
  
    function getProperties(instanceSettings, tweenSettings, params) {
      const properties = [];
      const settings = mergeObjects(instanceSettings, tweenSettings);
      Object.keys(params).forEach((p) => {
        if (!settings.hasOwnProperty(p) && p !== 'targets') {
          properties.push({
            name: p,
            offset: settings.offset,
            tweens: normalizePropertyTweens(params[p], tweenSettings),
          });
        }
      });
      return properties;
    }
  
    // Tweens
  
    function normalizeTweenValues(tween, animatable) {
      const t = {};
  
      Object.keys(tween).forEach((p) => {
        let value = getFunctionValue(tween[p], animatable);
        if (is.arr(value)) {
          value = value.map(v => getFunctionValue(v, animatable));
          if (value.length === 1) value = value[0];
        }
        t[p] = value;
      });
  
      t.duration = parseFloat(t.duration);
      t.delay = parseFloat(t.delay);
      return t;
    }
  
  
    function normalizeTweens(prop, animatable) {
      let previousTween;
      return prop.tweens.map((t) => {
        const tween = normalizeTweenValues(t, animatable);
        const tweenValue = tween.value;
        const originalValue = animatable.target[prop.name];
        const previousValue = previousTween ? previousTween.to.original : originalValue;
        const from = is.arr(tweenValue) ? tweenValue[0] : previousValue;
        const to = tweenValue;
        tween.from = decomposeValue(from);
        tween.to = decomposeValue(to);
        tween.start = previousTween ? previousTween.end : prop.offset;
        tween.end = tween.start + tween.delay + tween.duration;
        tween.easing = easings[tween.easing];
        tween.elasticity = (1000 - minMaxValue(tween.elasticity, 1, 999)) / 1000;
        if (tween.isColor) tween.round = 1;
        previousTween = tween;
        return tween;
      });
    }
  
    // Tween progress
  
    const setTweenProgress = {
      css: (t, p, v) => t.style[p] = v,
      attribute: (t, p, v) => t.setAttribute(p, v),
      object: (t, p, v) => t[p] = v,
      transform: (t, p, v, transforms, id) => {
        if (!transforms[id]) transforms[id] = [];
        transforms[id].push(`${p}(${v})`);
      },
    };
  
    // Animations
  
    function createAnimation(animatable, prop) {
      const animType = getAnimationType(animatable.target, prop.name);
      if (animType) {
        const tweens = normalizeTweens(prop, animatable);
        return {
          type: animType,
          property: prop.name,
          animatable,
          tweens,
          duration: tweens[tweens.length - 1].end,
          delay: tweens[0].delay,
        };
      }
    }
  
    function getAnimations(animatables, properties) {
      return filterArray(flattenArray(animatables.map(animatable => properties.map(prop => createAnimation(animatable, prop)))), a => !is.und(a));
    }
  
    // Create Instance
  
    function getInstanceTimings(type, animations, instanceSettings, tweenSettings) {
      const isDelay = (type === 'delay');
      if (animations.length) {
        return (isDelay ? Math.min : Math.max).apply(Math, animations.map(anim => anim[type]));
      }
      return isDelay ? tweenSettings.delay : instanceSettings.offset + tweenSettings.delay + tweenSettings.duration;
    }
  
    function createNewInstance(params) {
      const instanceSettings = replaceObjectProps(defaultInstanceSettings, params);
      const tweenSettings = replaceObjectProps(defaultTweenSettings, params);
      const animatables = getAnimatables(params.targets);
      const properties = getProperties(instanceSettings, tweenSettings, params);
      const animations = getAnimations(animatables, properties);
      return mergeObjects(instanceSettings, {
        children: [],
        animatables,
        animations,
        duration: getInstanceTimings('duration', animations, instanceSettings, tweenSettings),
        delay: getInstanceTimings('delay', animations, instanceSettings, tweenSettings),
      });
    }
  
    // Core
  
    const activeInstances = [];
    let raf = 0;
  
    const engine = (() => {
      function play() { raf = requestAnimationFrame(step); }
      function step(t) {
        const activeLength = activeInstances.length;
        if (activeLength) {
          let i = 0;
          while (i < activeLength) {
            if (activeInstances[i]) activeInstances[i].tick(t);
            i += 1;
          }
          play();
        } else {
          cancelAnimationFrame(raf);
          raf = 0;
        }
      }
      return play;
    })();
  
  
    // Public Instance
  
    function anime(params = {}) {
      let now,
        startTime,
        lastTime = 0;
  
      let resolve = null;
  
      function makePromise() {
        return window.Promise && new Promise(_resolve => resolve = _resolve);
      }
  
      let promise = makePromise();
  
      const instance = createNewInstance(params);
  
      function toggleInstanceDirection() {
        instance.reversed = !instance.reversed;
      }
  
      function adjustTime(time) {
        return instance.reversed ? instance.duration - time : time;
      }
  
      function syncInstanceChildren(time) {
        const { children } = instance;
        const childrenLength = children.length;
        if (time >= instance.currentTime) {
          for (let i = 0; i < childrenLength; i += 1) children[i].seek(time);
        } else {
          for (let i = childrenLength; i--;) children[i].seek(time);
        }
      }
  
      function setAnimationsProgress(insTime) {
        let i = 0;
        const transforms = {};
        const animations = instance.animations;
        const animationsLength = animations.length;
        while (i < animationsLength) {
          const anim = animations[i];
          const animatable = anim.animatable;
          const tweens = anim.tweens;
          const tweenLength = tweens.length - 1;
          let tween = tweens[tweenLength];
          // Only check for keyframes if there is more than one tween
          if (tweenLength) tween = filterArray(tweens, t => (insTime < t.end))[0] || tween;
          const elapsed = minMaxValue(insTime - tween.start - tween.delay, 0, tween.duration) / tween.duration;
          const eased = isNaN(elapsed) ? 1 : tween.easing(elapsed, tween.elasticity);
  
          const numbers = [];
          let progress;
          const toNumbersLength = tween.to.numbers.length;
          for (let n = 0; n < toNumbersLength; n += 1) {
            let value;
            const toNumber = tween.to.numbers[n];
            const fromNumber = tween.from.numbers[n];
  
            value = fromNumber + (eased * (toNumber - fromNumber));
  
            numbers.push(value);
          }
          // Manual Array.reduce for better performances
  
          progress = numbers[0];
  
          setTweenProgress[anim.type](animatable.target, anim.property, progress, transforms, animatable.id);
          anim.currentValue = progress;
          i += 1;
        }
  
        instance.currentTime = insTime;
        instance.progress = (insTime / instance.duration) * 100;
      }
  
      function setCallback(cb) {
        if (instance[cb]) instance[cb](instance);
      }
  
      function countIteration() {
        if (instance.remaining && instance.remaining !== true) {
          instance.remaining -= 1;
        }
      }
  
      function setInstanceProgress(engineTime) {
        const insDuration = instance.duration;
        const insOffset = instance.offset;
        const insStart = insOffset + instance.delay;
        const insCurrentTime = instance.currentTime;
        const insReversed = instance.reversed;
        const insTime = adjustTime(engineTime);
        if (instance.children.length) syncInstanceChildren(insTime);
        if (insTime >= insStart || !insDuration) {
          if (!instance.began) {
            instance.began = true;
            setCallback('begin');
          }
          setCallback('run');
        }
        if (insTime > insOffset && insTime < insDuration) {
          setAnimationsProgress(insTime);
        } else {
          if (insTime <= insOffset && insCurrentTime !== 0) {
            setAnimationsProgress(0);
            if (insReversed) countIteration();
          }
          if ((insTime >= insDuration && insCurrentTime !== insDuration) || !insDuration) {
            setAnimationsProgress(insDuration);
            if (!insReversed) countIteration();
          }
        }
        setCallback('update');
        if (engineTime >= insDuration) {
          if (instance.remaining) {
            startTime = now;
            if (instance.direction === 'alternate') toggleInstanceDirection();
          } else {
            instance.pause();
            if (!instance.completed) {
              instance.completed = true;
              setCallback('complete');
              if ('Promise' in window) {
                resolve();
                promise = makePromise();
              }
            }
          }
          lastTime = 0;
        }
      }
  
      instance.reset = function () {
        const direction = instance.direction;
        const loops = instance.loop;
        instance.currentTime = 0;
        instance.progress = 0;
        instance.paused = true;
        instance.began = false;
        instance.completed = false;
        instance.reversed = direction === 'reverse';
        instance.remaining = direction === 'alternate' && loops === 1 ? 2 : loops;
        setAnimationsProgress(0);
        for (let i = instance.children.length; i--;) {
          instance.children[i].reset();
        }
      };
  
      instance.tick = function (t) {
        now = t;
        if (!startTime) startTime = now;
        const engineTime = (lastTime + now - startTime) * anime.speed;
        setInstanceProgress(engineTime);
      };
  
      instance.seek = function (time) {
        setInstanceProgress(adjustTime(time));
      };
  
      instance.pause = function () {
        const i = activeInstances.indexOf(instance);
        if (i > -1) activeInstances.splice(i, 1);
        instance.paused = true;
      };
  
      instance.play = function () {
        if (!instance.paused) return;
        instance.paused = false;
        startTime = 0;
        lastTime = adjustTime(instance.currentTime);
        activeInstances.push(instance);
        if (!raf) engine();
      };
  
      instance.reverse = function () {
        toggleInstanceDirection();
        startTime = 0;
        lastTime = adjustTime(instance.currentTime);
      };
  
      instance.restart = function () {
        instance.pause();
        instance.reset();
        instance.play();
      };
  
      instance.finished = promise;
  
      instance.reset();
  
      if (instance.autoplay) instance.play();
  
      return instance;
    }
  
    anime.speed = 1;
    anime.running = activeInstances;
    anime.getValue = getOriginalTargetValue;
    anime.bezier = bezier;
    anime.easings = easings;
  
    return anime;
  }));