const crypto = require('crypto');

// get list of supportable encryption algorithms
export const getAlgorithmList = () => {
    console.log(crypto.getCiphers());
};

export const generateKey = () => {
    return crypto.randomBytes(32);
};

export const generateIv = () => {
    return crypto.randomBytes(16);
};

export const encrypt = (key, iv, text) => {
    try
    {
        let encrypted = '';
        let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key, 'base64'), Buffer.from(iv, 'base64'));
        encrypted += cipher.update(Buffer.from(text), 'utf8', 'base64');
        encrypted += cipher.final('base64');

        return encrypted;
    }
    catch(err)
    {
        throw new Error(`AES encryption failed! : ${err}`);
    }
};

export const decrypt = (key, iv , text) => {
    try
    {
        let dec = '';
        let cipher = crypto.createDecipheriv('aes-256-cbc', key,  iv);
        dec += cipher.update(Buffer.from(text, 'base64'), 'base64', 'utf8');
        dec += cipher.final('utf8');

        return dec;
    }
    catch(err)
    {
        throw new Error(`AES decryption failed! : ${err}`);
    }
};


