import { atom} from 'jotai'
import { MySeatAtom } from './MySeatAtom'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const MyTurnAtom = atom( (get) => {
    const mySeat = get(MySeatAtom)
    const state = get(pokerTableStateAtom)

    if (mySeat && state)
    {
        return (mySeat.no === state.turn)
    }

    return false

})