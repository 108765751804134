import { atom} from 'jotai'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const MoneyOnTableAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)

    if (state && state.seats)
    {
        let result = 0
        state.seats.forEach(s => {
            result += s.lastRoundPutOnTable
        });

        return result
    }

    return 0

})