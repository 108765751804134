import { encrypt, decrypt } from "./aes-wrapper"



export const sendMessage = (msg, socket) => {

    if (socket && socket.socket && socket.socket.connected && socket.sharedKey) {
        const msg_enc = encrypt(socket.sharedKey.key, socket.sharedKey.iv, JSON.stringify(msg));
        socket.socket.emit('message', msg_enc)
        return true
    }
    else {
        return false
    }
}
