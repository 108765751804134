import { baseStyle } from "@chakra-ui/react";

export const modalStyles = {
    components: {
        Modal: {
          baseStyle: {
            dialogContainer: {
              "@supports(height: -webkit-fill-available)": {},
            },
          },
        },
      }
}