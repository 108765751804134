import { atom} from 'jotai'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const MinBetAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    if (state)
    {
        return state.minBet
    }
    return 0   
})