import { atom} from 'jotai'
import {pokerTableStateAtom} from './pokerTableStateAtom'

export const GameStateAtom = atom( (get) => {

    const state = get(pokerTableStateAtom)
    if (state)
    {
        return state.state
    }

    return -1
   
})