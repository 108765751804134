import { atom} from 'jotai'
import { getHandSolve } from 'utils/game-utils'
import { getHandName } from 'utils/game-utils'
import { holeCardsAtom } from './holeCardsAtom'
import { MySeatAtom } from './MySeatAtom'
import {pokerTableStateAtom} from './pokerTableStateAtom'


export const MyHandSolveAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    const mySeat = get(MySeatAtom)
    const holeCards = get(holeCardsAtom)
    if (state && mySeat && holeCards)
    {
        const result = getHandSolve([...holeCards, ...(state.communityCards)])
        // console.log(result)
        return result
    }
    return ''
})