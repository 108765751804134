// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Portal,
  Progress,
  SimpleGrid,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Center,
  useColorMode,
  useColorModeValue,
  Spinner,
  Avatar,
  ButtonGroup,
  HStack,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Tooltip,
  useDisclosure,
  Input,
  Badge
} from "@chakra-ui/react";
import { canvasDimensionsAtom } from "atoms/canvasDimensionsAtom";
import { fullScreenModeAtom } from "atoms/fullScreenModeAtom";
import { useAtom } from "jotai";
// assets
// Custom icons
import React, { useState, useEffect } from "react";
import useWindowDimensions from "utils/useWindowDimensions";
// react icons

import "./GameDashboard-Mobile.css"

import TopArea from "game/TopArea";
import { sendMessage } from "utils/publisher";
import { socketAtom } from "atoms/socketAtom";
import { subscribeForMessage } from "utils/subscriber";
import { pokerTableStateAtom } from "atoms/pokerTableStateAtom";
import { stateHistoryAtom } from "atoms/statesHistoryAtom";
import { endSubscription } from "utils/subscriber";
import { accountAtom } from "atoms/accountAtom";
import { holeCardsAtom } from "atoms/holeCardsAtom";
import { MySeatAtom } from "atoms/MySeatAtom";
import { muteSoundAtom } from "atoms/muteSoundAtom";


import chtreeImage from "assets/img/others/chtree.png"

import {BiVolumeMute} from "react-icons/bi";
import {FiVolume2} from "react-icons/fi"
import {ImExit} from "react-icons/im"
import {HiOutlineEye} from "react-icons/hi"

import {MdChair} from "react-icons/md"

import {FiMenu} from "react-icons/fi"

import {BsInfoCircle} from "react-icons/bs"

import {BsChatSquareDots} from "react-icons/bs"


// import {AiOutlineFullscreen, AiOutlineFullscreenExit} from "react-icons/ai"

import {BsFullscreen, BsFullscreenExit} from "react-icons/bs"

import "animate.css"
import Chat from "components/Chat/Chat";
import { chatMessagesAtom } from "atoms/chatMessagesAtom";
import { userNameAtom } from "atoms/userNameAtom";
import { LeaveTableAtom } from "atoms/LeaveTableAtom";
import { MyTurnAtom } from "atoms/MyTurnAtom";


import { updateBalanceTriggerAtom } from "atoms/updateBalanceTriggerAtom";
import { viewersCountAtom } from "atoms/viewersCountAtom";
import getBackgroundStyles from "views/TableBackgrounds/BG-Style-Manager";

import ChatGIF from "assets/img/gif/chat.gif"
import adImage from "assets/img/others/goker-ad.png"

import { ToastContainer, toast } from 'react-toastify';
import './ReactToastify.css';
import PokerTableAreaMobile from "game/PokerTableArea-Mobile";

import logoImage from "assets/img/chips/joker-chip.png"
import WalletConnectButtonMobile from "components/Wallet/WalletConnectButton-Mobile";
import FooterAreaMobile from "game/FooterArea-Mobile";
import MobileSideMenu from "./MobileSideMenu";
import TableInfoDrawerMobile from "./TableInfoDrawer-Mobile";
import ChatDrawerMobile from "./ChatDrawer-Mobile";


window.onbeforeunload = (event) => {
  const e = event || window.event;
  // Cancel the event
  e.preventDefault();
  if (e) {
    e.returnValue = ''; // Legacy method for cross browser support
  }
  return ''; // Legacy method for cross browser support
};


export default function GameDashboardMobile(props) {


  // console.log("GameDashboard")

  const { height, width } = useWindowDimensions();

  const [canvasDimensions, setCanvasDimenssions] = useAtom(canvasDimensionsAtom)

  const [tableState, setTableState] = useAtom(pokerTableStateAtom)
  const [statesHistory, setStatesHistory] = useAtom(stateHistoryAtom)
  const [account] = useAtom(accountAtom)
  const [holeCards, setHoleCards] = useAtom(holeCardsAtom)

  const [muteSound, setMuteSound] = useAtom(muteSoundAtom)

  const [fullScreenMode, setFullScreenMode] = useAtom(fullScreenModeAtom)

  const [chatMessages, setChatMessages] = useAtom(chatMessagesAtom)

  const [leaveTable, setLeaveTable] = useAtom(LeaveTableAtom)

  const [updateBalanceTrigger, setUpdateBalanceTrigger] = useAtom(updateBalanceTriggerAtom)

  const [myTurn] = useAtom(MyTurnAtom)



  const [mySeat] = useAtom(MySeatAtom)

  const [socket] = useAtom(socketAtom)



  const { isOpen, onOpen, onClose } = useDisclosure()
  const menuRef = React.useRef()


  const tableInfoRef = React.useRef()
  const [isOpenTableInfo, setisOpenTableInfo] = React.useState(false)
  const onCloseTableInfo = () =>
  {
    setisOpenTableInfo(false)
  }

  const onOpenTableInfo = () =>
  {
    setisOpenTableInfo(true)
  }


  const chatRef = React.useRef()
  const [isOpenChat, setisOpenChat] = React.useState(false)
  const onCloseChat = () =>
  {
    setisOpenChat(false)
  }

  const onOpenChat = () =>
  {
    setisOpenChat(true)
    setNewChatsCount(prev => 0)
  }







  const getGameAreaStyles = (w, h, fullscreen) => {

    const data = getNewWidthHeight(w, h, fullscreen)
    const style = { width: `${data.newWidth}px`, height: `${data.newHeight}px`, marginTop: `${data.marginTop}px`, marginLeft: `${data.marginLeft}px`, fontSize: `${data.fontSize}em` }
    return style
  }

  const getNewWidthHeight = (w, h, fullscreen) => {
    let fontSize = newWidth / 1400
    const maxFontSize = 1.5
    if (fontSize > maxFontSize)
    {
      fontSize = maxFontSize
    }

    const minFontSize = 1
    if (fontSize < minFontSize)
    {
      fontSize = minFontSize
    }

    const newWidth = w
    const newHeight = h
    const marginTop = 0
    const marginLeft = 0

    return { newWidth, newHeight, marginTop, marginLeft, fontSize }
  }

  useEffect(() => {
    const data = getNewWidthHeight(width, height, fullScreenMode)
    setCanvasDimenssions({ width: data.newWidth, height: data.newHeight })
  }, [width, height, fullScreenMode])


  const isConnected = () => {
    return socket && socket.socket && socket.socket.connected && socket.sharedKey && account 
  }

  useEffect(() => {
    if (props.tableId) {

      const sub1 = subscribeForMessage("updateTableState", updateTableState, "gamedash")
      const sub2 = subscribeForMessage("requestViewTableError", requestViewTableError, "gamedash")
      const sub3 = subscribeForMessage("updateHoleCards", updateHoleCards, "gamedash")
      const sub4 = subscribeForMessage("chatMessage", chatMessageRecieved, "gamedash")

      const sub5 = subscribeForMessage("broadcastMessage", broadcastMessageRecieved, "gamedash")

      const sub6 = subscribeForMessage("updateViewersCount", updateViewersCount, "gamedash")


      if (props.tableId && isConnected()) {
        sendMessage({ type: "requestViewTable", tableId: props.tableId }, { socket: socket.socket, sharedKey: socket.sharedKey })
      }

      return () => {
        endSubscription(sub1)
        endSubscription(sub2)
        endSubscription(sub3)
        endSubscription(sub4)
        endSubscription(sub5)
        endSubscription(sub6)
      }
    }

  }, [props.tableId, socket])




  const [viewersCount, setViewersCount] = useAtom(viewersCountAtom)
  const updateViewersCount = (msg) =>
  {
    if (msg.tableId === props.tableId) {
        setViewersCount(msg.count)
    }

  }

  const [newChatsCount, setNewChatsCount] = useState(0)
  const chatMessageRecieved = (msg) => {
    setChatMessages(prev => [...prev, msg.data])
  }

  useEffect(() => {

    if (chatMessages && chatMessages.length > 0)
    {
      if (!isOpenChat)
      {
        setNewChatsCount( newChatsCount + 1)
      }
    }

  }, [chatMessages])

  const broadcastMessageRecieved = (msg) => {
    const {message, title, severity, timeout} = msg

    const text = (
      <div>
        <h2>{title}</h2>
        <p>{message}</p>
      </div>
    )
    const options = {
      position: "top-center",
      autoClose: timeout,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }

      if (severity === "info")
      {
        toast.info(text,options)

      }else if (severity === "success")
      {
        toast.success(text,options)

      }else if (severity === "error")
      {
        toast.error(text,options)
      }else if (severity === "warning")
      {
        toast.warn(text,options)        
      }
  }


  const requestViewTableError = (msg) => {

    const text = (
      <div>
        <h2>ERROR</h2>
        <p>The poker table does not exist or is not available right now.</p>
      </div>
    )
    const options = {
      position: "top-center",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      }

      toast.error(text, options)

  }

  const updateTableState = (msg) => {

    if (msg.tableId === props.tableId) {
      setStatesHistory(prev => [...prev, msg.state])
      setTableState({ tableId: msg.tableId,
                     tableName: msg.tableName, 
                     tableType: msg.tableType, 
                     theme: msg.theme, 
                     minBalance: msg.minBalance, 
                     maxBalance: msg.maxBalance,
                     minPlayersToStart: msg.minPlayersToStart,
                     waitTimeToStart: msg.waitTimeToStart,
                     breakTimeBetweenHands: msg.breakTimeBetweenHands,
                     actionTime : msg.actionTime,
                     minBet : msg.minBet,

                       ...msg.state
                      }
                    )
    }


  }

  const updateHoleCards = (msg) =>
  {
    if (msg.tableId === props.tableId) {
      setHoleCards([...msg.holeCards])
    }
  }


    const ChristmassTree = () =>
    {
      return (
        <div className="chtree-image">
          <img src={chtreeImage} />
        </div>
      )
    }

  const leaveMySeat = () => {
    sendMessage({type: "requestImmediateLeaveSeat", tableId: props.tableId }, socket)
    setTimeout(() => {
      setUpdateBalanceTrigger(prev => !prev)
    }, 1000);
  }

  const requestLeaveTable = (leave) => {
    setLeaveTable(leave)
    sendMessage({type: "requestLeaveSeat", leave: leave}, socket)
  }

  const containerRef = React.useRef() 

  return (
    <div ref={containerRef} className={`gameArea-mobile ${isConnected() && " gameArea-mobile-connected"}`} style={getGameAreaStyles(width, height, fullScreenMode)}>

        <ToastContainer
          theme="dark"
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />


        <div style={isConnected() ? { display: "block", position:"relative", width:"100%", height:"100%" , overflow:"hidden"} : {display: "none", overflow:"hidden"}}>          


            <div className={`gameCanvas-mobile`}>
              <div style={{ position: "relative", width: "100%", height: "100%"}}>

                <PokerTableAreaMobile tableTheme={props.tableTheme}/>
                
                {mySeat && 
                   <FooterAreaMobile />
                }

                {!mySeat && 
                  <div className="take-seat-message-mobile">
                    Tap on a "Take Seat" to play on this table
                  </div>  
                }

                <div className="mobile-menu-icon">
                    <Icon onClick={onOpen} ref={menuRef} as={FiMenu} style={{color:"#eee", fontSize:"1.5em"}}/>
                </div>

              <MobileSideMenu isOpen={isOpen} onClose={onClose} menuRef={menuRef}/>
              <TableInfoDrawerMobile isOpen={isOpenTableInfo} onClose={onCloseTableInfo} menuRef={tableInfoRef}/>

              <ChatDrawerMobile isOpen={isOpenChat} onClose={onCloseChat} menuRef={chatRef}/>


              <Tooltip label="Online Viewers">
                <div className="viewers-count-icon-mobile">
                  <Icon as={HiOutlineEye} color="#ddd" fontSize={"1em"}/>
                  <span className="viewers-count-label" style={{fontSize:"0.8em", color:"#ddd"}}>
                    {viewersCount}
                  </span>
                </div>
              </Tooltip>

              <div className="table-info-icon-mobile">
                  <Icon onClick={onOpenTableInfo} ref={tableInfoRef} as={BsInfoCircle} color="#75dfff" fontSize={"1em"}/>
              </div>

              <div className="chat-icon-mobile">
                  <Icon onClick={onOpenChat} ref={chatRef} as={BsChatSquareDots} color="#75dfff" fontSize={"1.5em"}/>
                  {newChatsCount > 0 && (
                      <div className="chat-icon-mobile-badge">
                       <Badge fontSize={"xs"} bg="#ff2652" color="#fff"> {newChatsCount} </Badge>
                      </div>
                  )}
                 

              </div>
 
             
              </div>
            </div>

          </div> 


      <div style={!isConnected() ? { display: "flex", position: "relative", width: "100%", height: "100%" } : { display: "none" }}>
        <img src={logoImage} alt="logo" style={{ position: "fixed", width: `${height / 4}px`, left: `${width / 2 - height / 8}px`, top: `${height / 12}px` }} />
        <div className="connect-wallet-welcome-mobile" style={{ top: `${height * 0.37}px`, width:`${width}px`  }} >
          <p>
            WELCOME TO
          </p>
            <h2> GREEN JOKER </h2>
        </div>

        <div className="connect-wallet-message-mobile" style={{ top: `${height * 0.5}px`, width:`${width}px` }} >
          Just connect your wallet and enjoy playing <span className="poker-text-highlite">POKER</span> online!
        </div>

        <div className="connect-wallet-button-mobile" style={{ top: `${height * 0.65}px`, width:`${width}px` }} >
          <WalletConnectButtonMobile/>
        </div>



      </div>
    </div>


  )

}

