import { atom} from 'jotai'

export const PlayerPositionsAtom = atom(
    [
        {pos: 1, targetPos: 1},
        {pos: 2, targetPos: 2},
        {pos: 3, targetPos: 3},
        {pos: 4, targetPos: 4},
        {pos: 5, targetPos: 5},
        {pos: 6, targetPos: 6},
        {pos: 7, targetPos: 7},
        {pos: 8, targetPos: 8},
        {pos: 9, targetPos: 9},

    ]
)