import avatar1 from "../assets/img/avatars/joker/j1.png"
import avatar2 from "../assets/img/avatars/joker/j2.png"
import avatar3 from "../assets/img/avatars/joker/j3.png"
import avatar4 from "../assets/img/avatars/joker/j4.png"
import avatar5 from "../assets/img/avatars/joker/j5.png"
import avatar6 from "../assets/img/avatars/joker/j6.png"
import avatar7 from "../assets/img/avatars/joker/j7.png"

import avatar8 from "../assets/img/avatars/face/f1.png"
import avatar9 from "../assets/img/avatars/face/f2.png"
import avatar10 from "../assets/img/avatars/face/f3.png"
import avatar11 from "../assets/img/avatars/face/f4.png"
import avatar12 from "../assets/img/avatars/face/f5.png"
import avatar13 from "../assets/img/avatars/face/f6.png"
import avatar14 from "../assets/img/avatars/face/f7.png"
import avatar15 from "../assets/img/avatars/face/f8.png"
import avatar16 from "../assets/img/avatars/face/f9.png"
import avatar17 from "../assets/img/avatars/face/f10.png"
import avatar18 from "../assets/img/avatars/face/f11.png"
import avatar19 from "../assets/img/avatars/face/f12.png"

import avatar20 from "../assets/img/avatars/stock/s1.png"
import avatar21 from "../assets/img/avatars/stock/s2.png"
import avatar22 from "../assets/img/avatars/stock/s3.png"
import avatar23 from "../assets/img/avatars/stock/s4.png"
import avatar24 from "../assets/img/avatars/stock/s5.png"
import avatar25 from "../assets/img/avatars/stock/s6.png"
import avatar26 from "../assets/img/avatars/stock/s7.png"
import avatar27 from "../assets/img/avatars/stock/s8.png"
import avatar28 from "../assets/img/avatars/stock/s9.png"
import avatar29 from "../assets/img/avatars/stock/s10.png"

import avatar30 from "../assets/img/avatars/cartoon/c1.png"
import avatar31 from "../assets/img/avatars/cartoon/c2.png"
import avatar32 from "../assets/img/avatars/cartoon/c3.png"
import avatar33 from "../assets/img/avatars/cartoon/c4.png"
import avatar34 from "../assets/img/avatars/cartoon/c5.png"

import avatar35 from "../assets/img/avatars/santa/ch1.png"
import avatar36 from "../assets/img/avatars/santa/ch2.png"
import avatar37 from "../assets/img/avatars/santa/ch3.png"
import avatar38 from "../assets/img/avatars/santa/ch4.png"
import avatar39 from "../assets/img/avatars/santa/ch5.png"
import avatar40 from "../assets/img/avatars/santa/ch6.png"
import avatar41 from "../assets/img/avatars/santa/ch7.png"
import avatar42 from "../assets/img/avatars/santa/ch8.png"
import avatar43 from "../assets/img/avatars/santa/ch9.png"





const avatars = [
    {name: "j1" , src: avatar1},
    {name: "j2" , src: avatar2},
    {name: "j3" , src: avatar3},
    {name: "j4" , src: avatar4},
    {name: "j5" , src: avatar5},
    {name: "j6" , src: avatar6},
    {name: "j7" , src: avatar7},

    {name: "f1" , src: avatar8},
    {name: "f2" , src: avatar9},
    {name: "f3" , src: avatar10},
    {name: "f4" , src: avatar11},
    {name: "f5" , src: avatar12},
    {name: "f6" , src: avatar13},
    {name: "f7" , src: avatar14},
    {name: "f8" , src: avatar15},
    {name: "f9" , src: avatar16},
    {name: "f10" , src: avatar17},
    {name: "f11" , src: avatar18},
    {name: "f12" , src: avatar19},

    {name: "s1" , src: avatar20},
    {name: "s2" , src: avatar21},
    {name: "s3" , src: avatar22},
    {name: "s4" , src: avatar23},
    {name: "s5" , src: avatar24},
    {name: "s6" , src: avatar25},
    {name: "s7" , src: avatar26},
    {name: "s8" , src: avatar27},
    {name: "s9" , src: avatar28},
    {name: "s10" , src: avatar29},

    {name: "c1" , src: avatar30},
    {name: "c2" , src: avatar31},
    {name: "c3" , src: avatar32},
    {name: "c4" , src: avatar33},
    {name: "c5" , src: avatar34},

    {name: "ch1" , src: avatar35},
    {name: "ch2" , src: avatar36},
    {name: "ch3" , src: avatar37},
    {name: "ch4" , src: avatar38},
    {name: "ch5" , src: avatar39},
    {name: "ch6" , src: avatar40},
    {name: "ch7" , src: avatar41},
    {name: "ch8" , src: avatar42},
    {name: "ch9" , src: avatar43},


]

export const getAvatar = (name) => {
    const av = avatars.find(e => e.name === name)
    return av?.src
}

export const getAllAvatars = () =>
{
    return avatars
}