import { atom} from 'jotai'
import { getHandName } from 'utils/game-utils'
import { holeCardsAtom } from './holeCardsAtom'
import { MySeatAtom } from './MySeatAtom'
import {pokerTableStateAtom} from './pokerTableStateAtom'


export const MyHandNameAtom = atom( (get) => {
    const state = get(pokerTableStateAtom)
    const mySeat = get(MySeatAtom)
    const holeCards = get(holeCardsAtom)
    if (state && mySeat && holeCards)
    {
        return getHandName([...holeCards, ...(state.communityCards)])
    }
    return ''
})