// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Icon,
  Image,
  Portal,
  Progress,
  SimpleGrid,
  Spacer,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Center,
  useColorMode,
  useColorModeValue,
  useToast,
  Spinner,
  Avatar,
  ButtonGroup,
  HStack,
  VStack,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import { canvasDimensionsAtom } from "atoms/canvasDimensionsAtom";

import { useAtom } from "jotai";
import React, { useState, useEffect } from "react";
import { getAvatar } from "utils/avatars";

import "./PokerTableSeat.css"
import TimeProgressBar from "./TimeProgressBar";

import {AiOutlineUser} from "react-icons/ai"
import {CgUserlane} from "react-icons/cg"
import PokerCard from "./PokerCard";

import GreenChipImage from "../../assets/img/chips/joker-chip.png"
import { MySeatAtom } from "atoms/MySeatAtom";
import { GameStateAtom } from "atoms/GameStateAtom";
import { CurrentRoundAtom } from "atoms/CurrentRoundAtom";
import { ActionTimeAtom } from "atoms/ActionTimeAtom";
import { getTimeLeft } from "utils/game-utils";
import { HandStartedAtom } from "atoms/HandStartedAtom";

import useSound from 'use-sound';

import dealingCardSFX from '../../assets/sounds/cards/dealing-card-2.flac';
import dealerButtonSFX from '../../assets/sounds/chips/dealer-button.flac'


import knockOnTableSFX from '../../assets/sounds/others/knock-1.flac'


import { muteSoundAtom } from "atoms/muteSoundAtom";
import { showPotChipsAtom } from "atoms/showPotChipsAtom";
import { pokerTableStateAtom } from "atoms/pokerTableStateAtom";
import { MoneyOnTableAtom } from "atoms/MoneyOnTableAtom";

import WinnerImage from "../../assets/img/others/winner-trophy.svg"
import winnerSoundSFX from '../../assets/sounds/others/winner.flac';
import { getHandName } from "utils/game-utils";

import dealerButtonImage from "../../assets/img/chips/dealer-button.png"
import { showMyHandBoxAtom } from "atoms/showMyHandBoxAtom";
import ChipStack from "game/ChipStack/ChipStack";




import "animate.css"
import { getRingGif } from "views/TableBackgrounds/BG-Style-Manager";
import { getActivePlayerBorderStyle } from "views/TableBackgrounds/BG-Style-Manager";
import { getPlayerMoneyColor } from "views/TableBackgrounds/BG-Style-Manager";
import { getHandSolve } from "utils/game-utils";


 function PokerTableSeat(props) {


  // console.log("PokerTableSeat")

  const [canvasDimensions] = useAtom(canvasDimensionsAtom)

  const [mySeat] = useAtom(MySeatAtom)
  const [gameState] = useAtom(GameStateAtom)
  const [currentRound] = useAtom(CurrentRoundAtom)

  const [playDealingCard] = useSound(dealingCardSFX); 
  const [playDealerButton] = useSound(dealerButtonSFX, {volume: 0.05}); 
  // const [playChipsInButton] = useSound(chipsInSFX); 

  const [muteSound] = useAtom(muteSoundAtom)

  const [frontCard1, setFrontCard1] = React.useState(false)
  const [frontCard2, setFrontCard2] = React.useState(false)

  const [moveToCenter, setMoveToCenter] = React.useState(false)
  const [showPotChips, setShowPotChips] = useAtom(showPotChipsAtom)

  const [moneyOnTable] = useAtom(MoneyOnTableAtom)

  const [tableState] = useAtom(pokerTableStateAtom)

  const [showMyHandBox, setShowMyHandBox] = useAtom(showMyHandBoxAtom)



  const [playWinnerSound] = useSound(winnerSoundSFX, {volume: 0.2});
  const [playKnockOnTable] = useSound(knockOnTableSFX, {volume: 0.8});


  // useEffect( () => {

  //   if (props.cards && props.cards.length === 2)
  //   {
  //     if (currentRound <= 1 && mySeat && mySeat.no !== props.no) {
  //       setFrontCard1(false)
  //       setFrontCard2(false)
  //       setShowMyHandBox(false)

  //       setTimeout(() => {
  //         setFrontCard1(true)
  //         setFrontCard2(true)
  //         setShowMyHandBox(true)
  //       }, 8000);

  //     } else if (currentRound === 5 && mySeat && mySeat.no !== props.no) {
  //       setFrontCard1(false)
  //       setFrontCard2(false)

  //       setTimeout(() => {
  //         setFrontCard1(true)
  //         setFrontCard2(true)
  //       }, 1000);
  //     } else if (props.cards && props.cards.length === 2 && mySeat && mySeat.no !== props.no) {
  //       setFrontCard1(true)
  //       setFrontCard2(true)
  //       setShowMyHandBox(true)
  //     }

  //   }


  // }, [props.cards, currentRound])



  useEffect( () => {
    if (props.moveToCenter)
    {
      setTimeout(() => {
        setMoveToCenter(true)
      }, 500);


      if (moneyOnTable > 0) {
        setTimeout(() => {
          setShowPotChips(true)
        }, 700);
      }

    }else
    {
      setMoveToCenter(false)
    }

  }, [props.moveToCenter])

  useEffect( () => {
    if (props.winner)
    {
      setTimeout(() => {
        playSound(playWinnerSound)
        
      }, 1000);
    }

  }, [props.winner])


  const [actionTime] = useAtom(ActionTimeAtom)

  const getPokerTableSeatStyles = (width, height) => {
    let style = {}

    const { seatNo } = props
    if (seatNo) {
      if (seatNo === 9) {
          style = { ...style, top: "83%", left: "37%" }
      } else if (seatNo === 1) {
        style = { ...style, top: "75%", left: "-5%" }

      } else if (seatNo === 2) {
        style = { ...style, top: "40%", left: "-13%" }

      } else if (seatNo === 3) {
        style = { ...style, top: "10%", left: "-7%" }

      } else if (seatNo === 4) {
        style = { ...style, top: "-14%", left: "15%" }

      } else if (seatNo === 5) {
        style = { ...style, top: "-14%", left: "66%" }

      } else if (seatNo === 6) {
        style = { ...style, top: "10%", left: "89%" }
      }
      else if (seatNo === 7) {
        style = { ...style, top: "40%", left: "95%" }

      } else if (seatNo === 8) {
        style = { ...style, top: "75%", left: "87%" }

      } 
      else {
        style = { display: 'none' }
      }
    }



    let fontSize = width / 1600
    const maxFontSize = 0.7
    if (fontSize > maxFontSize)
    {
      fontSize = maxFontSize
    }

    style = {...style, fontSize: `${fontSize}em`}

    if (props.fold)
    {
      style={...style, opacity: 0.4}
    }else if (props.waiting){
      style={...style, opacity: 0.6}
    }

    return style
  }

  const isLeft = (no) =>
  {
    const leftPlayers = [1,2,3,4]
    return (leftPlayers.indexOf(no) >= 0)
  }

  const [showLastAction, setShowLastAction] = useState(false)

  const [updateTrigger, setUpdateTrigger] = useState(false)

  useEffect( () => {

    // const interval = setInterval(() => {
    //   setUpdateTrigger( prev => !prev)
    // }, 500)

    return () => {
      // clearInterval(interval)
    }

  }, [])



  useEffect( () => {
    if (props.lastAction && props.lastAction.trim().length > 0)
    {
      setShowLastAction(true)
      if (props.lastAction && (props.lastAction.toLowerCase() === "check")){
        playSound(playKnockOnTable)
      }
      setTimeout(() => {
        setShowLastAction(false)
      }, 5000);
    }

  }, [props.lastAction, props.lastActionCounter])



  const takeSeat = () => {
    props.takeSeat(props.seatNo, props.no)
  }

  const playSound = (soundFunction) => {
    if (!muteSound) {
      soundFunction()
    }
  }

  const getMoveToCenterSyles = (seatNo) =>
  {
    let top, left, bottom, right = null

    if (seatNo === 1)
    {
      top = -220
      left = 270
    }
    else if (seatNo === 2)
    {
      top = -90
      left = 320
    }
    else if (seatNo === 3)
    {
      top = 0
      left = 320
    }
    else if (seatNo === 4)
    {
      top = 150
      left = 170
    }
    else if (seatNo === 5)
    {
      top = 150
      left = -70
    }
    else if (seatNo === 6)
    {
      top = 0
      left = -230
    }
    else if (seatNo === 7)
    {
      top = -100
      left= -280
    }
    else if (seatNo === 8)
    {
      top = -200
      left= -200
    }
    else if (seatNo === 9)
    {
      if (mySeat && mySeat?.pos === 9)
      {
        top = -180
      }else
      {
        top = -270
      }
      left= 40
    }

    let style = {}
    if (left)
    {
      style = {...style, left: `${left}%`}
    }
    if (top)
    {
      style = {...style, top: `${top}%`}
    }
    if (bottom)
    {
      style = {...style, bottom: `${bottom}%`}
    }
    if (right)
    {
      style = {...style, right: `${right}%`}
    }

    return {...style, opacity: 0}
  }

  const getChipsLabelDir = (no) => {
    switch (no) {
      case 1:
        return "bottom"
      case 2:
        return "bottom"
      case 3:
        return "bottom"
      case 4:
        return "bottom"
      case 5:
        return "bottom"
      case 6:
        return "bottom"
      case 7:
        return "bottom"
      case 8:
        return "bottom"
      case 9:
          return "left"       
      default:
        return "bottom"
    }
  }

  const getChipsDir = (no) =>
  {
    switch (no) {
      case 1:
        return "left"
      case 2:
        return "left"
      case 3:
        return "left"
      case 4:
        return "right"
      case 5:
        return "left"
      case 6:
        return "right"
      case 7:
        return "right"
      case 8:
        return "right"
      case 9:
          return "left"
      default:
        return "left"
    }
  }

  const isWinnerCard = (card) => {
    try{

     if (tableState.state === 3 && tableState.round === 5) {
       if (props.winner) {
         const selectedCards = []
         const winnerHoleCards = props.cards
         const winnerHandSolve = getHandSolve([...(tableState.communityCards), ...winnerHoleCards])
         if (winnerHandSolve) {
           winnerHandSolve.cards?.forEach(ca => {

             if (`${ca.value}${ca.suit}` === card) {
               // console.log({card})
               if (selectedCards.indexOf(card) < 0) {
                 selectedCards.push(card)
               }
             }
           })
         }

         return (selectedCards.indexOf(card) >= 0)
       }
     }

     return false

    }catch(err)
    {
      return false
    }

   }



  const [prevMoney, setPrevMoney] = useState(0)
   const [moneyRaiseAmount, setmoneyRaiseAmount] = useState([])
   useEffect(() => {

     if (tableState.state === 3) {

       if (props.money > prevMoney) {
         moneyRaised(props.money - prevMoney)
       }

       setPrevMoney(props.money)
     }

   }, [props.money])

   const moneyRaised = (diff) =>
   {
     const _temp = moneyRaiseAmount
     _temp.push(diff)
     setmoneyRaiseAmount([..._temp])

     setTimeout(() => { 
       setmoneyRaiseAmount(prev => prev.filter(a => a !== diff))
     }, 3000);
   }


  return (
    <div className={` poker-table-seat-area poker-table-seat-area-${props.seatNo} `} style={getPokerTableSeatStyles(canvasDimensions.width, canvasDimensions.height, props)}>
      <div className="poker-table-seat-container" style={props.empty && mySeat ? {display:"none"} : {}}>
          <div style={props.turn ? getActivePlayerBorderStyle(props.tableTheme): {}} className={`poker-table-seat-player-info-box ${props.turn && "poker-table-seat-player-info-box-blink"} ${isLeft(props.seatNo) ? "poker-table-seat-player-info-box-left" : "poker-table-seat-player-info-box-right"}`}>
            <div className={`${isLeft(props.seatNo) ? "poker-table-seat-player-info-box-inside" : "poker-table-seat-player-info-box-inside-right"}`}>
              
            {!props.empty && (
              <React.Fragment>
                {showLastAction && props.lastAction && <p className="poker-table-seat-player-info-box-inside-action"> {props.lastAction.toUpperCase()} </p>}
                {(!showLastAction || !props.lastAction) && <p className="poker-table-seat-player-info-box-inside-name"> {props.name} </p>} 
                <hr />
                <p style={{color: getPlayerMoneyColor(props.tableTheme)}} className="poker-table-seat-player-info-box-inside-money">  {(props.money === 0 && !props.dontShowAllIn) ? "ALL-IN" : `${props.money?.toLocaleString("us", { minimumFractionDigits: 0 })}` } </p>
              </React.Fragment>
            )}

            {props.empty && (!mySeat) && (
              <React.Fragment>
                <div className="poker-table-seat-player-info-box-inside-name-empty" onClick={takeSeat}>
                   {!mySeat && "Take Seat"}
                </div>
              </React.Fragment>
            )} 

            {props.empty && (mySeat) && (
              <React.Fragment>
                <div className="poker-table-seat-player-info-box-inside-name-empty" style={{cursor:"not-allowed"}}>
                  Empty Seat
                </div>
              </React.Fragment>
            )}

            </div>
          </div>

        {props.turn && (
          <div className={isLeft(props.seatNo) ? "poker-table-seat-player-avatar-active-gif-left" : "poker-table-seat-player-avatar-active-gif-right"}>
            <img src={getRingGif(props.tableTheme)} style={{ borderRadius: "50%" }} />
          </div>
        )}


          <div className={`poker-table-seat-player-avatar ${props.turn && "poker-table-seat-player-avatar-blink"} ${isLeft(props.seatNo) ? "poker-table-seat-player-avatar-left" : "poker-table-seat-player-avatar-right"}`}>
            {!props.empty && props.avatar && (              
              <img className="poker-table-seat-avatar-image" src={getAvatar(props.avatar)} />
            )}

            {!props.empty && !props.avatar && (
              <div className="poker-table-seat-avatar-image-noavatar">
                <Icon as={CgUserlane} />
              </div>
            )}

            {props.empty && (
              <div className="poker-table-seat-avatar-image-empty">
                <Icon as={AiOutlineUser}/>
              </div>
            )}
          </div>

          <div className={`${isLeft(props.seatNo) ? "poker-table-seat-player-time" : "poker-table-seat-player-time-right"}`} >
             {props.turn && gameState === 2 && currentRound > 0 && <TimeProgressBar timeOut={props.timeOut} actionTime={actionTime}/> } 
          </div>

        <div style={moveToCenter ? getMoveToCenterSyles(props.seatNo): {opacity: 1}}  onAnimationStart={() =>  setTimeout(() => playSound(playDealerButton), 100)}  className={`poker-table-seat-player-chips ` + `${(mySeat && mySeat?.pos === 9 && props.seatNo === 9) ? `poker-table-seat-player-chips-${props.seatNo}-me` : `poker-table-seat-player-chips-${props.seatNo}` }` + ((props.chipsAnimation && props.chipsSeatNo === props.no) ? ` poker-table-seat-player-chips-animation-${props.seatNo}  ` : "") + ((props.chipsHidden && props.chipsSeatNo === props.no) ? " hidden " : "")}>
          {props.showBetOnTable &&  (
            <React.Fragment>
              <ChipStack amount={props.lastBetValue} labelDir={getChipsLabelDir(props.seatNo)} chipDir={getChipsDir(props.seatNo)} />
            </React.Fragment>
          )}
        </div>


            <div onAnimationStart={() =>  setTimeout(() => playSound(playDealerButton), 400)}  className={`poker-table-seat-player-dealer-button poker-table-seat-player-dealer-button-${props.seatNo} ` + (props.dealerButtonAnimation ? " dealer-button-animation " : "") + (props.dealerButtonHidden ? " hidden " : "")}>
                {props.isDealer && (
                      <img src={dealerButtonImage} style={{width:"100%", height:"100%"}}/>
                )}
            </div>


            {/* {!props.empty && !props.fold && !props.waiting && (!props.cards || props.cards.length === 0) && gameState > 1 && currentRound > 0 &&  (
              <React.Fragment>
                <div 
                  onAnimationStart={() => playSound(playDealingCard)}
                  className={ "poker-table-seat-player-cards-back " + (isLeft(props.seatNo) ? "poker-table-seat-player-cards-card1" : "poker-table-seat-player-cards-card1-right") + (props.card1Animation ? " card-animation " : "") + (props.card1Hidden ? " hidden " : "") }>
                    <PokerCard />
                </div>
                <div onAnimationStart={() => playSound(playDealingCard)} 
                className={ "poker-table-seat-player-cards-back " + (isLeft(props.seatNo) ? "poker-table-seat-player-cards-card2" : "poker-table-seat-player-cards-card2-right") + (props.card2Animation ? " card-animation " : "") + (props.card2Hidden ? " hidden " : "") }>
                  <PokerCard />
                </div>
              </React.Fragment>
            )} */}

          <div
            style={!props.empty && !props.fold && !props.waiting && (!props.cards || props.cards.length === 0) && gameState > 1 && currentRound > 0 ? { display: "block" } : { display: "none" }}
            onAnimationStart={() => playSound(playDealingCard)}
            className={"poker-table-seat-player-cards-back " + (isLeft(props.seatNo) ? "poker-table-seat-player-cards-card1" : "poker-table-seat-player-cards-card1-right") + (props.card1Animation ? ` card-animation-${props.seatNo} ` : "") + (props.card1Hidden ? " hidden " : "")}>
            <PokerCard />
          </div>
          <div
            style={!props.empty && !props.fold && !props.waiting && (!props.cards || props.cards.length === 0) && gameState > 1 && currentRound > 0 ? { display: "block" } : { display: "none" }}
            onAnimationStart={() => playSound(playDealingCard)}
            className={"poker-table-seat-player-cards-back " + (isLeft(props.seatNo) ? "poker-table-seat-player-cards-card2" : "poker-table-seat-player-cards-card2-right") + (props.card2Animation ? ` card-animation-${props.seatNo} ` : "") + (props.card2Hidden ? " hidden " : "")}>
            <PokerCard />
          </div>



          {!props.empty && !props.waiting && (props.cards && props.cards.length === 2) && gameState > 1 && currentRound > 0 && (
            <React.Fragment>
              <div onAnimationStart={() => playSound(playDealingCard)} 
                  className={(props.winner && isWinnerCard(props.cards[0]) ? "poker-table-seat-player-cards-front-winner " : "poker-table-seat-player-cards-front ") + (isLeft(props.seatNo) ? "poker-table-seat-player-cards-card1" : "poker-table-seat-player-cards-card1-right") + (props.card1Animation ? (mySeat && mySeat?.no === props.no && props.seatNo === 9 ? " card-animation-1-front-me " : ` card-animation-${props.seatNo} `) : "") + (props.card1Hidden ? " hidden " : "") }>
                <PokerCard front={true} card={props.cards[0]} />
              </div>
              <div onAnimationStart={() => playSound(playDealingCard)} 
                  className={(props.winner && isWinnerCard(props.cards[1]) ? "poker-table-seat-player-cards-front-winner " : "poker-table-seat-player-cards-front ")  + (isLeft(props.seatNo) ? "poker-table-seat-player-cards-card2" : "poker-table-seat-player-cards-card2-right") + (props.card2Animation ? (mySeat && mySeat?.no === props.no && props.seatNo === 9 ? " card-animation-2-front-me " : ` card-animation-${props.seatNo} `) : "") + (props.card2Hidden ? " hidden " : "") }>
                <PokerCard front={true} card={props.cards[1]} />
              </div>
            </React.Fragment>
          )}

        {tableState && tableState.communityCards && props.cards && props.cards.length === 2 && (mySeat?.no !== props.no) && !props.empty && !props.fold && !props.waiting && (
          <div className={isLeft(props.seatNo) ? "poker-table-seat-hand-name" : "poker-table-seat-hand-name-right"}>
            {getHandName([...(props.cards), ...(tableState.communityCards) ])}
          </div>
         )}

        {props.winner && !props.empty && (
          <div className={isLeft(props.seatNo) ? "poker-table-seat-winner-image" : "poker-table-seat-winner-image-right"}>
            <img src={WinnerImage} alt="winner" />
          </div>
         )} 


        {!props.empty && moneyRaiseAmount  && moneyRaiseAmount.length > 0 && tableState.state === 3 && (
           moneyRaiseAmount.map( raise => (
                <div className="poker-table-seat-winner-money-add-desktop">
                   {`+ ${raise}`}
               </div>  
           ))

         )}


      </div>
    </div>
  )

}


export default PokerTableSeat;