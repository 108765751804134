import { atom} from 'jotai'
import { stateHistoryAtom } from './statesHistoryAtom'

export const HandStartedAtom = atom( (get) => {
     const stateHistory = get(stateHistoryAtom)
     if (stateHistory && stateHistory.length > 1)
     {
         return (
                (stateHistory[stateHistory.length-2].state === 1 && stateHistory[stateHistory.length-1].state === 2) ||
                (stateHistory[stateHistory.length-2].state === 3 && stateHistory[stateHistory.length-1].state === 2)
                )
     }

     return false
})
